import { CompanyHead } from '@/equities/companyHead'
import { DataTable } from '@/common/ui/data-table'
import { OrderT } from '@/common/types'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'

type orderProps = {
    orders: OrderT[]
}

export function Orders(props: orderProps) {
    const { orders } = props
    const columns: ColumnDef<OrderT>[] = useMemo(
        () => [
            {
                accessorKey: 'securityCode',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Company</span>
                },
                cell: ({ row }) => {
                    const securityCode = row.original.securityCode
                    return (
                        <CompanyHead
                            nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                            instrument={{
                                securityCode: securityCode,
                                companyName: row.original.companyName,
                            }}
                        />
                    )
                },
            },
            {
                accessorKey: 'orderSide',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Type</span>
                },
                cell: ({ row }) => {
                    return <span className="capitalize">{row.original.orderSide.toLowerCase()}</span>
                },
            },
            {
                accessorKey: 'units',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Quantity</span>
                },
            },
            {
                accessorKey: 'status',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Status</span>
                },
            },
        ],
        [],
    )

    return <DataTable columns={columns} data={orders} noDataMessage="No current orders..." />
}
