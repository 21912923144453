import React, { useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'

import { LabelInput } from '@/common/ui/LabelInput'
import { H2 } from '@/common/ui'
import { Button } from '@/common/ui/button'
import useAuthStore from '@/auth/authStore'

const schema = yup
    .object({
        confirmationCode: yup.string().required('Please enter confirmation code'),
        newPassword: yup
            .string()
            .min(6, 'Password should be atleast 6 characters long')
            .max(50, 'Password can not be longer than 50 characters')
            .required(),
        confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match'),
    })
    .required()

type Props = {
    onReset?: () => void
    onSignInClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function ResetPassword({ onReset, onSignInClick }: Props) {
    const [isResetSuccess, setIsResetSuccess] = useState(false)
    const setIsForgotPassword = useAuthStore((state) => state.setIsForgotPassword)
    const [isRunning, setIsRunning] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })
    const username = useAuthStore((state) => state.username)

    const onSubmit = async (data: { newPassword: string; confirmationCode: string }) => {
        const { confirmationCode, newPassword } = data
        try {
            setIsRunning(true)
            await confirmResetPassword({ username, confirmationCode, newPassword })
            setIsResetSuccess(true)
            onReset && onReset()
        } catch (error: any) {
            console.log('error setting new password', error)
            toast.error(error.message)
        } finally {
            setIsRunning(false)
        }
    }

    const handleResendCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        resetPassword({ username })
    }

    if (isResetSuccess && !onReset) {
        return (
            <div className="text-center">
                <H2>Successfully reset password</H2>
                <p className="mt-6 mb-8">You have reset the password successfully! Please log in.</p>
                <Button
                    variant="secondary"
                    type="submit"
                    onClick={() => {
                        setIsForgotPassword(false)
                    }}
                >
                    Log In
                </Button>
            </div>
        )
    }

    return (
        <div>
            <H2 className="text-center">Reset Password</H2>
            <form className="flex flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
                <LabelInput
                    type="text"
                    {...register('confirmationCode')}
                    id="confirmation-code"
                    formNoValidate
                    required
                    label="Confirmation Code"
                    error={errors.confirmationCode?.message}
                />
                <LabelInput
                    {...register('newPassword')}
                    error={errors.newPassword?.message}
                    required
                    type="password"
                    id="new-password"
                    label="New Password"
                />
                <LabelInput
                    {...register('confirmPassword')}
                    error={errors.confirmPassword?.message}
                    required
                    type="password"
                    id="confirm-password"
                    label="Confirm password"
                />
                <div className="flex flex-wrap justify-between items-center pt-4">
                    <Button loading={isRunning} variant="secondary" size="lg" type="submit">
                        Submit
                    </Button>
                    <Button variant="link" className="p-1 h-8" onClick={handleResendCode}>
                        Resend Code
                    </Button>
                </div>
                {onSignInClick && (
                    <Button variant="link" className="ml-auto p-1 h-8" onClick={onSignInClick}>
                        Sign in
                    </Button>
                )}
            </form>
        </div>
    )
}
