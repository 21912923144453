import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/common/utils/utils'
import { Spinner } from './spinner'

const buttonVariants = cva(
    'rounded-full text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed',
    {
        variants: {
            variant: {
                default: 'bg-primary text-primary-foreground hover:bg-primary/90',
                destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                outline: 'border border-input bg-background hover:bg-accent',
                tertiary: 'border border-input bg-accent hover:bg-gray',
                secondary: 'bg-electric-lime text-secondary-foreground hover:bg-pear',
                ghost: 'border md:border-0 hover:bg-accent',
                link: 'text-primary underline-offset-4 hover:underline',
            },
            size: {
                default: 'h-10 px-6 py-2',
                sm: 'h-9 rounded-full px-3 py-1.5',
                lg: 'h-13 rounded-full px-8 py-2.5',
                icon: 'h-10 w-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
    focused?: boolean
    loading?: boolean
    innerClassName?: string
    midClassName?: string
    fullWidth?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            focused,
            loading,
            innerClassName,
            midClassName,
            size,
            asChild = false,
            fullWidth = false,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : 'button'
        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className }), {
                    'ring-2 ring-secondary-light': focused,
                    'cursor-not-allowed': loading,
                    'w-full': fullWidth,
                })}
                ref={ref}
                disabled={loading}
                {...props}
            >
                <div className={cn('flex gap-2 items-center justify-center', midClassName)}>
                    <span
                        className={cn(
                            'inline-flex items-center justify-center whitespace-nowrap gap-2',
                            innerClassName,
                        )}
                    >
                        {props.children}
                    </span>
                    {loading && <Spinner />}
                </div>
            </Comp>
        )
    },
)
Button.displayName = 'Button'

export { Button }
