import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import ReactDOM from 'react-dom/client'
import { Toaster } from '@/common/ui/sonner'
import { AxiosInterceptorProvider } from './common/api/AxiosInterceptorProvider'
import { AppRouterProvider } from './common/router/Provider'
import { initSentry } from './common/errorHandling/Sentry'
import './index.css'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React from 'react'
import UserProvider from './user/UserProvider'
import AuthProvider from './auth/AuthProvider'
import SixTermsProvider from './user/policies/SixTermsProvider'
import KycProvider from './kyc/KycProvider'
import { TradeModalProvider } from './trading/TradeModalProvider'

Amplify.configure({
    aws_project_region: 'ap-southeast-2',
    // @ts-ignore
    aws_cognito_region: 'ap-southeast-2',
    aws_user_pools_id: import.meta.env.VITE_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
        domain: import.meta.env.VITE_AWS_OAUTH_DOMAIN,
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: import.meta.env.VITE_OAUTH_REDIRECT_SIGNIN,
        redirectSignOut: import.meta.env.VITE_OAUTH_REDIRECT_SIGNOUT,
        responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_TOOLS',
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS'],
    },
    aws_cognito_verification_mechanisms: [],
})

initSentry()

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false,
        },
    },
})

;(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: import.meta.env.VITE_LD_CLIENT_SIDE_ID,
    })

    ReactDOM.createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <LDProvider>
                <QueryClientProvider client={queryClient}>
                    <AxiosInterceptorProvider>
                        <Toaster />
                        <SixTermsProvider>
                            <AuthProvider>
                                <UserProvider>
                                    <KycProvider>
                                        <TradeModalProvider>
                                            <AppRouterProvider />
                                        </TradeModalProvider>
                                    </KycProvider>
                                </UserProvider>
                            </AuthProvider>
                        </SixTermsProvider>
                    </AxiosInterceptorProvider>
                </QueryClientProvider>
            </LDProvider>
        </React.StrictMode>,
    )
})()
