import { Helmet } from 'react-helmet'

import { CampaignsList } from '@/campaigns/CampaignsList'
import { useFetchCampaignsList, useFetchSubscriptions } from '@/campaigns/campaignsQueries'
import { H1 } from '@/common/ui/h1'
import { APP } from '@/common/strings'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import Banner from './Banner'

export function Campaigns() {
    const { data: campaignsList } = useFetchCampaignsList()
    const { data: subscriptions } = useFetchSubscriptions()

    if (!campaignsList) return <FullScreenLoader />

    return (
        <>
            <Helmet>
                <title>Campaigns | {APP.title}</title>
            </Helmet>
            <H1>Campaigns</H1>
            <Banner />
            <CampaignsList campaignsList={campaignsList} subscriptions={subscriptions} />
        </>
    )
}
