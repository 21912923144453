import { cn } from '@/common/utils/utils'

type Props = {
    children: React.ReactNode
    className?: string
}

export function H3({ children, className }: Props) {
    return <h2 className={cn('text-2xl md:text-3xl mb-2 font-medium', className)}>{children}</h2>
}
