import { Button, LabelInput } from '@/common/ui'
import { DialogDescription, DialogHeader, DialogTitle } from '@/common/ui/dialog'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Undo2 as Back, CircleAlertIcon } from 'lucide-react'
import { AccountDetailsFormInput } from './types'
import { Checkbox } from '@/common/ui/checkbox'

const schema = yup
    .object({
        bankAccountName: yup.string().required('Please enter account name').min(2, 'Please enter a valid title'),
        bsb: yup
            .string()
            .required('Please enter your bsb number')
            .length(7, 'Please enter a valid bsb number')
            .matches(/^\d{3}-\d{3}$/, 'Please enter a valid bsb number'),
        bankAccountNumber: yup
            .string()
            .required('Please enter your bank account number')
            .min(6, 'Please enter a valid bank account number')
            .max(10, 'Please enter a valid bank account number')
            .matches(/^\d{6,10}$/, 'Please enter a valid bank account number'),
        confirmation: yup.boolean().oneOf([true], 'I confirm that the details I have provided are correct'),
    })
    .required()

export type AccountDetails = {
    bankAccountName: string
    bsb: string
    bankAccountNumber: string
    updatedAt?: string
    createdAt?: string
    userId?: string
    version?: string
}

type Props = {
    onSave: (accountDetails: AccountDetailsFormInput) => void
    accountDetails?: AccountDetailsFormInput
    savingAccountDetails: boolean
    handleBack: () => void
}

export default function AccountDetailsForm(props: Props) {
    const [detailsConfirmed, setDetailsConfirmed] = useState(false)

    const {
        register,
        handleSubmit,
        control,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    })

    useEffect(() => {
        setValue('bankAccountName', props.accountDetails?.bankAccountName || '')
        setValue('bsb', props.accountDetails?.bsb || '')
        setValue('bankAccountNumber', props.accountDetails?.bankAccountNumber || '')
    }, [props.accountDetails, setValue])

    const onSubmit: SubmitHandler<AccountDetailsFormInput> = async (data) => {
        props.onSave(data)
    }

    return (
        <DialogHeader>
            <DialogTitle className="text-center text-3xl">
                {props.accountDetails ? 'Edit a bank account' : 'Add a bank account'}
            </DialogTitle>
            <DialogDescription className="text-center">
                Add your bank account to enable withdrawals. Please note that your bank account name must match to your
                SIX account name.
            </DialogDescription>
            <div className="w-[80%] m-auto">
                <form noValidate onSubmit={handleSubmit(onSubmit)} className="relative z-0 w-full max-w-xl">
                    <div className="py-6 w-full mb-auto">
                        <LabelInput
                            {...register('bankAccountName')}
                            type="text"
                            id="bankAccountName"
                            required
                            formNoValidate
                            label="Account Name"
                            placeholder="Account name"
                            error={errors.bankAccountName?.message}
                        />
                        <LabelInput
                            {...register('bsb')}
                            type="text"
                            id="bsb"
                            required
                            formNoValidate
                            label="BSB number"
                            placeholder="BSB number"
                            error={errors.bsb?.message}
                        />
                        <LabelInput
                            {...register('bankAccountNumber')}
                            type="text"
                            id="bankAccountNumber"
                            required
                            formNoValidate
                            label="Account number"
                            placeholder="Account number"
                            error={errors.bankAccountNumber?.message}
                        />
                    </div>
                    <div className="bg-accent p-4 rounded-tl-3xl rounded-br-3xl p-4 flex">
                        <CircleAlertIcon width={60} height={24} className="self-start" />
                        <p className="text-base ml-2 text-[#33404F]">
                            Ensure that you double-check that the details you've provided are correct. If in the case
                            that the money is credited to the incorrect account, we cannot guarantee its recovery or
                            accept liability for any loss
                        </p>
                    </div>
                    <div className="flex gap-3 mt-4 items-center">
                        <Controller
                            name="confirmation"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => {
                                // @ts-ignore
                                const onChange = (checked) => {
                                    field.onChange()

                                    setDetailsConfirmed(checked as boolean)
                                    setValue('confirmation', checked as boolean)
                                    trigger('confirmation')
                                }
                                return (
                                    <Checkbox
                                        onCheckedChange={onChange}
                                        {...register('confirmation')}
                                        id="confirmation"
                                    />
                                )
                            }}
                        />
                        <label className="text-lg font-normal" htmlFor="confirmation">
                            I confirm that the details I have provided are correct
                        </label>
                    </div>
                    <div className="pt-6 w-full mb-auto flex justify-between">
                        <Button
                            type="submit"
                            variant={'secondary'}
                            loading={props.savingAccountDetails}
                            disabled={!detailsConfirmed}
                        >
                            Save account details
                        </Button>
                        <Button onClick={props.handleBack} variant={'ghost'} type="button">
                            <div className="bg-accent rounded-full p-1">
                                <Back />
                            </div>
                            Back
                        </Button>
                    </div>
                </form>
            </div>
        </DialogHeader>
    )
}
