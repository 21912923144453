import { InstrumentT } from '@/common/types'
import { Button } from '../common/ui/button'
import { cn } from '@/common/utils/utils'
import placeholderImg from '@/common/assets/intrument-placeholder.png'
import useCampaignStore from './campaignStore'

type Props = {
    instruments: InstrumentT[]
    pledges?: { [key: string]: number } | null
    selectedInstrument: InstrumentT | null
    setSelectedInstrument: (instrument: InstrumentT) => void
}
export const SecuritySelectButtons = ({ instruments, pledges, selectedInstrument, setSelectedInstrument }: Props) => {
    const { currentCampaign } = useCampaignStore()
    return (
        <div className="grid gap-4">
            {instruments.map((instrument) => {
                const instrumentCode = instrument.securityCode
                const isSelected = instrumentCode === selectedInstrument?.securityCode
                const shareholdersPledged = pledges ? pledges[`"${instrument.securityCode}"`] : 0
                const shareholdersGoal = currentCampaign?.pledgesGoal || 100 // FIXME: defaults to 100
                const percentOfTarget = Math.min((shareholdersPledged / shareholdersGoal) * 100, 100)
                    .toFixed(0)
                    .toString()

                return (
                    <Button
                        key={instrument.securityCode}
                        onClick={() => setSelectedInstrument(instrument)}
                        className={cn('h-auto w-full p-0 text-left bg-transparent border-1 overflow-hidden')}
                        midClassName="w-full"
                        innerClassName="relative flex justify-between items-center w-full"
                        variant="outline"
                        fullWidth
                    >
                        <style>{` :root { --bar-width: ${percentOfTarget}%; }`}</style>
                        <div className="absolute bg-white w-full h-full">
                            <div className={`bg-[#E4FBC6] w-[--bar-width] h-full`}></div>
                        </div>
                        <div className="z-10 flex px-4 py-1 items-center gap-4">
                            <div className="w-5 h-5 rounded-full border border-gray bg-white grid place-items-center">
                                <div
                                    className={cn(
                                        'rounded-full w-[10px] h-[10px]',
                                        `${isSelected ? 'bg-black' : 'bg-white'}`,
                                    )}
                                />
                            </div>
                            <div
                                className={cn('flex py-2 space-x-3 text-inherit w-max')}
                                title={instrument.companyName}
                            >
                                <div className="bg-gray-100 rounded-full p-1 w-8 h-8 sm:w-10 sm:h-10 border-1 border-gray-300 shrink-0 overflow-hidden bg-white">
                                    <img
                                        src={`/images/companies/${instrumentCode.toLowerCase()}.webp`}
                                        alt={instrumentCode}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null
                                            currentTarget.src = placeholderImg
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <span>
                                        <span className="font-semibold">{instrumentCode}</span>
                                        <span className="text-xs"> {instrument.companyName}</span>
                                    </span>
                                    <span className={cn('text-xs line-clamp-1')}>
                                        {shareholdersPledged}/{shareholdersGoal} shareholders
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Button>
                )
            })}
        </div>
    )
}
