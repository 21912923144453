import { useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useClickAway } from 'react-use'
import { Copy, Info } from 'lucide-react'

import Money from '@/common/models/money'
import { useFetchBalance, useFetchPayId } from '@/wallet/walletQueries'
import { Button, H2 } from '@/common/ui'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import { ValuesGrid } from '@/common/ui/ValuesGrid'
import { ValuesGridItem } from '@/common/ui/ValuesGridItem'
import WithdrawDialogContainer from './WithdrawDialogContainer'
import useKycStore from '@/kyc/kycStore'
import { seconds } from '@/common/utils/utils'
import { analytics } from '@/common/analytics/analytics'
import useUserStore from '@/user/userStore'

export default function FundsAndBalances() {
    const [isFundDetailsOpen, setIsFundDetailsOpen] = useState(false)
    const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false)
    const [isPayIdCopied, setIsPayIdCopied] = useState(false)
    const { kycComplete } = useKycStore()
    const { user } = useUserStore()
    const { data: payId } = useFetchPayId(kycComplete)
    const { data: balance } = useFetchBalance({
        enabled: kycComplete,
        refetchInterval: seconds(10),
    })
    const closeFundDetails = () => {
        setIsFundDetailsOpen(false)
        analytics.track('Money Pledged', {
            user: user?.emailId,
        })
    }

    const fundsModalRef = useRef(null)
    useClickAway(fundsModalRef, () => {
        closeFundDetails()
    })

    useEffect(() => {
        if (!isPayIdCopied) return
        setTimeout(() => {
            setIsPayIdCopied(false)
        }, 2000)
    }, [isPayIdCopied])

    return (
        <>
            <section>
                <div className="flex space-x-5">
                    <H2 className="mb-6">Funds &amp; Balances</H2>
                    <Button disabled={!payId} variant="secondary" onClick={() => setIsFundDetailsOpen(true)}>
                        Add Funds
                    </Button>
                    <Button
                        disabled={!(balance && balance.availableBalance > 0)}
                        variant="outline"
                        onClick={() => setOpenWithdrawDialog(true)}
                    >
                        Withdraw Funds
                    </Button>
                </div>
                <ValuesGrid>
                    <ValuesGridItem label="Available Balance" value={`${Money.of(balance?.availableBalance ?? 0)}`} />
                    <ValuesGridItem label="Total Balance" value={`${Money.of(balance?.totalBalance ?? 0)}`} />
                    <ValuesGridItem label="Settlements Expected" value={`${Money.ZERO}`} />
                </ValuesGrid>
            </section>

            {isFundDetailsOpen && payId && (
                <Dialog open={isFundDetailsOpen}>
                    <DialogContent
                        ref={fundsModalRef}
                        onClose={closeFundDetails}
                        className="w-11/12 md:w-4/5 lg:w-1/2 px-10 py-14 max-h-[90vh] overflow-y-auto"
                    >
                        <H2 className="mb-4">Add funds using PayID</H2>
                        <p>
                            To add funds to your wallet, go to your online banking and transfer to our account using the
                            email below.
                        </p>
                        {/* node */}
                        <p className="p-4 flex flex-col sm:flex-row items-start sm:items-center gap-3 my-6 bg-muted rounded-tl-3xl rounded-br-3xl">
                            <Info size={36} strokeWidth={1} fill="#fff" className="flex-shrink-0" />
                            <span>
                                Please note, your first transfer to this PayID may take up to 24 hours due to some
                                checks completed by your bank.
                            </span>
                        </p>

                        <div className="py-3 border-b">
                            <label className="font-light">Your unique PayID email</label>
                            <p className="flex space-x-3 font-medium">
                                <span>{payId.payId}</span>{' '}
                                <div className="relative">
                                    {isPayIdCopied && (
                                        <span className="absolute -top-10 -left-4 text-xs text-lime-700 font-semibold bg-white p-2 rounded border">
                                            Copied
                                        </span>
                                    )}
                                    <CopyToClipboard text={payId.payId} onCopy={() => setIsPayIdCopied(true)}>
                                        <button>
                                            <Copy />
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            </p>
                        </div>

                        <div className="py-3 border-b">
                            <label className="font-light">Account name</label>
                            <p className="flex space-x-3 font-medium">{payId.payIdName}</p>
                        </div>

                        <div className="py-3 border-b">
                            <label className="font-light">Our fee</label>
                            <p className="flex space-x-3 font-medium">{`${Money.ZERO}`}</p>
                        </div>

                        <div className="flex mt-6 flex-col sm:flex-row gap-5">
                            <Button onClick={closeFundDetails} className="w-full" variant="secondary">
                                I have paid
                            </Button>
                            <Button onClick={closeFundDetails} className="w-full" variant="outline">
                                I'll transfer later
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            )}

            {openWithdrawDialog && (
                <WithdrawDialogContainer
                    showWithdrawDialog={openWithdrawDialog}
                    onClose={() => setOpenWithdrawDialog(false)}
                    availableBalance={balance?.availableBalance || 0}
                />
            )}
        </>
    )
}
