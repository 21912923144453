import { UsagePolicy } from '@/common/types'
import { create } from 'zustand'

interface State {
    sixTerms: UsagePolicy | null
    setSixTerms: (sixTerms: UsagePolicy) => void
    userAcceptedSixTerms: boolean
    setUserAcceptedSixTerms: (hasAccepted: boolean) => void
}

const useSixTermsStore = create<State>((set) => ({
    sixTerms: null,
    setSixTerms: (sixTerms) => set(() => ({ sixTerms })),
    userAcceptedSixTerms: false,
    setUserAcceptedSixTerms: (hasAccepted) => set(() => ({ userAcceptedSixTerms: hasAccepted })),
}))

export default useSixTermsStore
