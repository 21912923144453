import { Helmet } from 'react-helmet'

import { APP } from '@/common/strings'
import { H1 } from '@/common/ui'

export function Research() {
    return (
        <div className="pt-6 pb-4 bg-white rounded-tl-[3rem] flex justify-center items-center">
            <Helmet>
                <title>Research | {APP.title}</title>
            </Helmet>
            <div className="w-40 h-16">
                <H1>Research</H1>
            </div>
        </div>
    )
}
