import { toast } from 'sonner'
import { API_ROUTES } from '@/common/constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
    getHistoricalPrice,
    getInstrument,
    getInstrumentPrices,
    getTradingAccount,
    listHoldings,
    listOrders,
    placeBuyOrder,
} from './tradingApi'
import { HistoricalPricesRangePreset, Maybe, OrderPayload } from '@/common/types'
import { defaultOptions } from '@/common/utils/queryOptions'

export const useFetchEquityDetails = (securityCode: Maybe<string>) => {
    return useQuery({
        queryKey: [API_ROUTES.instrumentProfile, securityCode],
        queryFn: () => getInstrument(securityCode || ''),
        enabled: !!securityCode,
    })
}

export const useFetchEquityPrices = (securityCode: Maybe<string>) => {
    return useQuery({
        queryKey: [API_ROUTES.instrumentPrices, securityCode],
        queryFn: () => getInstrumentPrices(securityCode || ''),
        enabled: !!securityCode,
    })
}

export const useFetchHistoricalPrice = (securityCode: Maybe<string>, rangePreset: HistoricalPricesRangePreset) => {
    return useQuery({
        queryKey: [API_ROUTES.historicalPrices, securityCode, rangePreset],
        queryFn: () => getHistoricalPrice(securityCode, rangePreset),
        enabled: !!securityCode,
    })
}

export const useFetchTradingAccount = () => {
    return useQuery({
        queryKey: [API_ROUTES.tradingAccount],
        queryFn: getTradingAccount,
    })
}

export const useListHoldings = (options = defaultOptions) => {
    const { enabled, refetchInterval } = options
    return useQuery({
        queryKey: [API_ROUTES.listHoldings],
        queryFn: listHoldings,
        enabled,
        refetchInterval,
    })
}

export const useListOrders = (options = defaultOptions) => {
    const { enabled, refetchInterval } = options
    return useQuery({
        queryKey: [API_ROUTES.listOrders],
        queryFn: listOrders,
        enabled,
        refetchInterval,
    })
}

export const usePlaceBuyOrder = () => {
    return useMutation({
        mutationFn: (order: OrderPayload) => placeBuyOrder(order),
        onSuccess: () => {},
        onError: (err: any) => {
            console.log('Failed to place the order', err)
            toast.error('Failed to place the order. Please try again')
        },
    })
}
