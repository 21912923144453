import { InstrumentT } from '@/common/types'
import { H2 } from '@/common/ui/h2'
import useTradeStore from '@/trading/tradeStore'
import { Button } from '@/common/ui/button'
import { Undo2 } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { usePlaceBuyOrder } from '@/trading/tradingQueries'
import { ReviewItems } from './revewItems'
import { Checkbox } from '@/common/ui/checkbox'
import useCampaignStore from './campaignStore'
import { usePledgeForCampaign } from '@/campaigns/campaignsQueries'
import { ORDER_ACTION } from '@/common/constants'

type Props = {
    instrument: InstrumentT
    pledges?: { [key: string]: number } | null
    onBackToOrder: () => void
    onSubmit: () => void
}
export const TradeReview: FC<Props> = ({ instrument, onBackToOrder, onSubmit }) => {
    const placeBuyOrder = usePlaceBuyOrder()
    const { currentCampaign } = useCampaignStore()
    const pledgeCampaign = usePledgeForCampaign()
    const action = useTradeStore((state) => state.orderAction)
    const requestId = useTradeStore((state) => state.requestId)
    const orderType = useTradeStore((state) => state.orderType)
    const limitPrice = useTradeStore((state) => state.limitPrice)
    const orderExpiry = useTradeStore((state) => state.orderExpiry)
    const numberOfShares = useTradeStore((state) => state.numberOfShares)
    const price = useTradeStore((state) => state.instrumentPrice)
    const sharesAmount = numberOfShares * (orderType === 'LIMIT' ? limitPrice : price)
    const [isPledged, setIsPledged] = useState<boolean>(true)

    const placeOrder = () => {
        if (!requestId) return
        if (isPledged) {
            pledgeForCampaign()
        }
        if (action === 'BUY') {
            placeBuyOrder.mutate({
                requestId,
                orderType,
                requestedPrice: sharesAmount,
                units: numberOfShares,
                validity: orderExpiry,
                securityCode: instrument.securityCode,
            })
        }
    }

    const pledgeForCampaign = () => {
        if (!currentCampaign) return
        pledgeCampaign.mutate({ campaignId: currentCampaign.campaignId, securityCode: instrument.securityCode })
    }

    useEffect(() => {
        if (placeBuyOrder.isSuccess) {
            onSubmit()
        }
    }, [placeBuyOrder.isSuccess, onSubmit])

    const PledgeCheckbox = () => (
        <div className="items-top flex space-x-2 items-center bg-[#E4FBC6] p-5">
            <Checkbox
                id="pledge"
                checked={isPledged}
                onCheckedChange={() => {
                    setIsPledged(!isPledged)
                }}
            />
            <div className="grid gap-1.5 leading-none">
                <label
                    htmlFor="pledge"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                    {`I wish pledge my ${instrument.companyName} shares to the resolution for the ${currentCampaign?.title}`}
                </label>
            </div>
        </div>
    )

    return (
        <div>
            <H2 className="text-center">Review {action === ORDER_ACTION.BUY ? 'Buy' : 'Sell'} Order</H2>
            <ReviewItems instrument={instrument} />
            <p className="my-5 text-sm">
                Trade confirmation will record brokerage charges and SIX does not accept any responsibility for market
                movements. You must ensure sufficient funds are available to cover the total consideration of the order.
            </p>
            {currentCampaign && <PledgeCheckbox />}
            <div className="flex justify-between items-center mt-8">
                <Button loading={placeBuyOrder.isPending} variant="secondary" size="lg" onClick={placeOrder}>
                    Submit Order
                </Button>
                <Button variant="link" onClick={onBackToOrder}>
                    Amend <Undo2 size={16} className="ml-2" />
                </Button>
            </div>
        </div>
    )
}
