import { Search, XCircle } from 'lucide-react'
import React from 'react'

type Props = {
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onClear: () => void
}

export const InstrumentsSearchForm: React.FC<Props> = ({ value, onChange, onClear }) => {
    return (
        <form
            className="mt-10 mb-6"
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div className="relative">
                <Search className="absolute top-3 left-4" />
                <input
                    type="text"
                    value={value}
                    name="search-term"
                    onChange={onChange}
                    className="w-full px-12 py-3 border border-gray-400 rounded-full"
                    placeholder="Search securities"
                />
                <button
                    onClick={onClear}
                    className="absolute right-4 top-4 disabled:opacity-50"
                    disabled={value.length === 0}
                >
                    <XCircle size={20} />
                </button>
            </div>
        </form>
    )
}
