import { useEffect } from 'react'
import { useFetchCampaignsList } from './campaignsQueries'

export const usePreloadCampaignsAndImages = () => {
    const { data } = useFetchCampaignsList()

    useEffect(() => {
        if (data?.campaigns) {
            data.campaigns.forEach((campaign) => {
                new Image().src = campaign.heroImage.url
            })
        }
    }, [data])
}
