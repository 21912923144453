import { H1 } from '@/common/ui/h1'
import FundsAndBalances from './FundsAndBalances'
import Transactions from './Transactions'

export default function Wallet() {
    return (
        <>
            <H1>Wallet</H1>
            <div className="grid gap-12">
                <FundsAndBalances />
                <Transactions />
            </div>
        </>
    )
}
