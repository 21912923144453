import { Check } from 'lucide-react'

import { H2, H3 } from '@/common/ui'
import { InstrumentT } from '@/common/types'
import { FC } from 'react'
import { ReviewItems } from './revewItems'
import { Button } from '../common/ui/button'

type Props = {
    instrument: InstrumentT
    onClose: () => void
}
export const OrderConfirmation: FC<Props> = ({ instrument, onClose }) => {
    return (
        <div>
            <span className="w-14 h-14 bg-secondary rounded-full mx-auto flex justify-center items-center">
                <Check />
            </span>
            <H2 className="text-center mt-2">Thanks, we have received your order</H2>
            <H3 className="text-center">Order summary</H3>
            <ReviewItems instrument={instrument} />
            <div>
                <Button onClick={onClose} className="mx-auto block mt-8" size="lg" variant="secondary">
                    Close
                </Button>
            </div>
        </div>
    )
}
