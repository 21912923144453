import { Button } from '@/common/ui'

export type AccountInfoItemProps = {
    title: string
    info: string
    editHandler?: () => void
}

export const AccountInfoItem = ({ title, info, editHandler }: AccountInfoItemProps) => {
    return (
        <li className="border-b py-4 xs:grid xs:grid-cols-[220px_1fr_100px] xs:min-h-16 xs:place-content-center">
            <div className="font-bold flex items-center">{title}</div>
            <div className="hidden xs:flex items-center">{info}</div>
            <Button
                disabled={!editHandler}
                className="hidden xs:block"
                variant="tertiary"
                size="sm"
                onClick={editHandler}
            >
                Edit
            </Button>
            <div className="flex justify-between xs:hidden">
                <div className="flex items-center pr-2">{info}</div>
                <Button disabled={!!editHandler} variant="tertiary" size="sm" onClick={editHandler}>
                    Edit
                </Button>
            </div>
        </li>
    )
}
