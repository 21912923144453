import { FC } from 'react'

import { ORDER_EXPIRY, ORDER_TYPE } from '@/common/constants'
import { InstrumentT } from '@/common/types'
import Money from '@/common/models/money'
import useTradeStore from '@/trading/tradeStore'
import { tradeEstimatedBrokerage } from '@/common/utils/utils'

type TradeReviewItemProps = {
    label: string
    value: string | number
}
const Item: FC<TradeReviewItemProps> = ({ label, value }) => {
    return (
        <li className="flex justify-between py-3 border-b">
            <span>{label}</span>
            <strong>{value}</strong>
        </li>
    )
}

type Props = {
    instrument: InstrumentT
}
export const ReviewItems: FC<Props> = ({ instrument }) => {
    const orderType = useTradeStore((state) => state.orderType)
    const limitPrice = useTradeStore((state) => state.limitPrice)
    const orderExpiry = useTradeStore((state) => state.orderExpiry)
    const numberOfShares = useTradeStore((state) => state.numberOfShares)
    const price = useTradeStore((state) => state.instrumentPrice)
    const sharesAmount = Money.of(orderType === 'LIMIT' ? limitPrice : price * 1.05).multiply(numberOfShares)
    const fee = tradeEstimatedBrokerage(sharesAmount)
    const totalAmount = sharesAmount.add(fee)
    return (
        <ul>
            <Item label="Company" value={`${instrument.companyName} (${instrument.securityCode})`} />
            <Item label="Order Type" value={ORDER_TYPE[orderType]} />
            {orderType === 'LIMIT' && <Item label="Expiry" value={ORDER_EXPIRY[orderExpiry]} />}
            <Item label="Shares" value={`${numberOfShares} shares`} />
            <Item label="Estimated Amount" value={`${sharesAmount}`} />
            <Item label="Estimated Brokerage" value={`${fee}`} />
            <Item label="Total" value={`${totalAmount}`} />
        </ul>
    )
}
