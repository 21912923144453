import { createBrowserRouter } from 'react-router-dom'
import { Campaigns } from '@/campaigns/CampaignsPage'
import { AppContainer } from '@/common/ui/AppContainer'
import { Profile } from '@/user/profile/ProfilePage'
import { Trading } from '@/trading/TradingPage'
import { Portfolio } from '@/portfolio/PortfolioPage'
import Wallet from '@/wallet/WalletPage'
import { Research } from '@/research/ResearchPage'
import { Kyc } from '@/kyc/KycPage'
import { EquityDetails } from '@/equities/EquityDetailsPage'
import { APP_ROUTES } from '@/common/constants'
import { KycInit } from '@/kyc/KycInitPage'
import { Address } from '@/user/address/AddressPage'
import { MobileVerification } from '@/user/mobile/MobileVerifyPage'
import { Name } from '@/user/name/NamePage'
import { MobileNumber } from '@/user/mobile/MobileNumberPage'
import { Survey } from '@/user/survey/SurveyPage'
import { OnboardingContainer } from '@/onboarding/OnboardingContainer'
import { CampaignDetailsPage } from '@/campaigns/CampaignDetailsPage'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <AppContainer />,
        children: [
            {
                path: '/',
                element: <Campaigns />,
            },
            {
                path: `${APP_ROUTES.CAMPAIGNS}/:campaignSlug`,
                element: <CampaignDetailsPage />,
            },
            {
                path: APP_ROUTES.PORTFOLIO,
                element: <Portfolio />,
            },
            {
                path: APP_ROUTES.TRADING,
                element: <Trading />,
            },
            {
                path: APP_ROUTES.WALLET,
                element: <Wallet />,
            },
            {
                path: `${APP_ROUTES.EQUITY}/:securityCode`,
                element: <EquityDetails />,
            },
            {
                path: APP_ROUTES.RESEARCH,
                element: <Research />,
            },
            {
                path: APP_ROUTES.PROFILE,
                element: <Profile />,
            },
        ],
    },
    {
        path: '/onboarding',
        element: <OnboardingContainer />,
        children: [
            {
                path: APP_ROUTES.NAME,
                element: <Name />,
            },
            {
                path: APP_ROUTES.ADDRESS,
                element: <Address />,
            },
            {
                path: APP_ROUTES.MOBILE_NUMBER,
                element: <MobileNumber />,
            },
            {
                path: APP_ROUTES.MOBILE_VERIFICATION,
                element: <MobileVerification />,
            },
            {
                path: APP_ROUTES.SURVEY,
                element: <Survey />,
            },
            {
                path: APP_ROUTES.KYC_INIT,
                element: <KycInit />,
            },
            {
                path: APP_ROUTES.KYC,
                element: <Kyc />,
            },
        ],
    },
])
