import { app } from '../common/api/apiClient'
import { type InitKYC as InitKYC_T, KYCStatus as KYCStatusT } from '@/common/types'
import { API_ROUTES } from '@/common/constants'

export async function initKYC(): Promise<InitKYC_T> {
    const res = await app.post(API_ROUTES.kycInit)
    return res.data
}

export async function requestKYCStatusUpdate(): Promise<any> {
    const res = await app.put(API_ROUTES.requestStatusUpdate)
    return res.data
}

export async function getKYC(): Promise<KYCStatusT> {
    const res = await app.get<KYCStatusT>(API_ROUTES.kyc)
    return res.data
}
