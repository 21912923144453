import Money from './models/money'

export const USER_ID_TOKEN = 'USER_ID_TOKEN'
export const SIX_WEBSITE_URL = 'https://www.six-invest.com.au'

export const APP_ROUTES = {
    NAME: '/onboarding/name',
    ADDRESS: '/onboarding/address',
    MOBILE_NUMBER: '/onboarding/mobile-number',
    MOBILE_VERIFICATION: '/onboarding/mobile-verification',
    SURVEY: '/onboarding/survey',
    KYC_INIT: '/onboarding/kyc-init',
    KYC: '/onboarding/kyc',
    PROFILE: '/profile',
    TRADING: '/trading',
    EQUITY: '/equity',
    CAMPAIGNS: '/campaigns',
    RESEARCH: '/research',
    PORTFOLIO: '/portfolio',
    ORDERS: '/orders',
    WALLET: '/wallet',
}

export const API_ROUTES = {
    userSetup: '/api/user/setup',
    user: '/api/user',
    /* Onboarding */
    userName: '/api/user/name',
    userContactDetails: '/api/user/contact-details',
    userAddresses: '/api/user/addresses',
    addressSearch: '/api/address-search/search',
    addressSearchById: '/api/address-search/address-id',
    survey: '/api/user/survey',
    usagePolicies: '/api/user/usage-policies/{}',
    acceptPolicy: '/api/user/accept-policy',

    /* Mobile */
    userMobile: '/api/user/mobile',
    verifyMobileOtp: '/api/user/mobile/verify',
    sendMobileOtp: '/api/user/mobile/otp',
    /* KYC */
    kycInit: '/api/user/kyc/init',
    kyc: '/api/user/kyc',
    requestStatusUpdate: '/api/user/kyc/request-status-update',
    /* Wallet */
    payId: '/api/user/wallet/deposit-options/payid',
    balance: '/api/user/wallet/balance',
    transactions: '/api/user/wallet/transactions',
    accountDetails: '/api/user/wallet/withdrawal-options/bank-account',
    withdraw: '/api/user/wallet/withdrawal-request',
    /* Market Data */
    topCompanies: '/api/market-data/asx/top-companies',
    instrumentSearch: '/api/market-data/asx/top-companies',
    instrumentProfile: '/api/market-data/security/{}/profile',
    instrumentPrices: '/api/market-data/security/{}/prices',
    historicalPrices: '/api/market-data/security/{}/historical-prices',
    /* Trading */
    tradingAccount: '/api/user/trading/account',
    buyOrder: '/api/user/buy-order',
    listOrders: '/api/user/orders',
    listHoldings: '/api/user/holdings',
    getCompanyByCode: 'api/companies/{}',
    /* Campaigns */
    campaignsList: '/api/campaigns/list',
    campaignDetails: '/api/campaigns/details/{}',
    campaignSubscriptions: '/api/user/campaigns/subscriptions',
    campaignSubscribe: '/api/user/campaigns/{}/subscribe',
    campaignUnsubscribe: '/api/user/campaigns/{}/unsubscribe',
    campaignPledge: '/api/user/campaigns/{}/{}/pledges',
}

export const USAGE_POLICIES = {
    SIX_TERMS: 'six-terms',
    FINCLEAR_TERMS: 'finclear-terms',
} as const

export const KYC_STATUS = {
    UNVERIFIED: 'UNVERIFIED',
    INITIATED: 'INITIATED',
    RE_INITIATED: 'RE_INITIATED',
    UNDER_VERIFICATION: 'UNDER_VERIFICATION',
    VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
    VERIFICATION_FAILED: 'VERIFICATION_FAILED',
} as const

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] as const

export const MIN_TRADE_FEE = Money.of(9.9)
export const TRADE_AMOUNT_FOR_PERCENTAGE = Money.of(10000)
export const TRADE_FEE_PERCENTAGE = 0.1

export const ORDER_EXPIRY = {
    VALID_TILL_EOD: 'Good till end of day',
    VALID_TILL_CANCEL: 'Good till cancelled',
}

export const ORDER_TYPE = {
    LIMIT: 'Limit',
    MARKET_TO_LIMIT: 'Market',
}

export const ORDER_ACTION = {
    BUY: 'Buy',
    SELL: 'Sell',
}
