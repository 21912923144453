import { Button } from '../common/ui/button'
import { useSubscribeCampaign, useUnsubscribeCampaign } from '@/campaigns/campaignsQueries'
import { useState } from 'react'
import { CampaignT } from '@/common/types'
import { H2, H3 } from '@/common/ui'
import { CampaignInvolvement } from './CampaignInvolvement'
import { Heart, Check, DollarSign, Share2 as Share } from 'lucide-react'
import { CampaignShareModal } from './CampaignShareModal'

export type CampaignWithSubscription = CampaignT & { isSubscribed?: boolean; pledged?: boolean }

export const Campaign = ({
    campaign,
    handleOrder,
}: {
    campaign: CampaignWithSubscription
    handleOrder: (asxCodes: string[]) => void
}) => {
    const subscribeCampaign = useSubscribeCampaign()
    const unsubscribeCampaign = useUnsubscribeCampaign()

    const [subscribed, setSubscribed] = useState<boolean>(campaign.isSubscribed ?? false)
    const [shared, setShared] = useState<boolean>(false)
    const [followers, setFollowers] = useState<number>(campaign.followers)
    const { pledged } = campaign

    const handleFollow = () => {
        setSubscribed(true)
        setFollowers(followers + 1)
        subscribeCampaign.mutate(campaign.campaignId)
    }

    const handleUnfollow = () => {
        setSubscribed(false)
        setFollowers(followers - 1)
        unsubscribeCampaign.mutate(campaign.campaignId)
    }

    const closeShareModal = () => {
        setShared(false)
    }

    const toggleFollow = () => {
        if (subscribed) {
            handleUnfollow()
        } else {
            handleFollow()
        }
    }

    return (
        <article
            key={campaign.campaignId}
            className="w-full mb-6 rounded-md border flex flex-col justify-between overflow-hidden"
        >
            <div className="relative">
                <div className="absolute h-48 w-full flex items-end justify-start">
                    <div className="w-36 h-[88px] bg-white rounded-tr-md border-b-1 flex flex-col justify-between">
                        <div className="h-full pl-4 flex flex-col justify-center">
                            <div className="font-display-bold text-4xl mb-[-6px]">{followers.toLocaleString()}</div>
                            <div className="text-sm">followers</div>
                        </div>
                        <div className="w-full h-1">
                            <div
                                className={'h-1 bg-electric-lime'}
                                style={{ width: `${Math.min((followers / campaign.followersGoal) * 100, 100)}%` }}
                            ></div>
                        </div>
                    </div>
                </div>
                <img className="h-48 w-full object-cover" src={campaign.heroImage.url} alt={campaign.heroImage.alt} />
                <div className="p-6 pb-0">
                    <a
                        className="text-black"
                        href={`/campaigns/${campaign.campaignId}`}
                        aria-label={`View ${campaign.title} campaign`}
                    >
                        <H2>{campaign.title}</H2>
                    </a>
                    <p className="font-bold mb-4">Our Goal: 100 Shareholders</p>
                    {campaign.description && <p className="pb-4">{campaign.description}</p>}
                </div>
            </div>
            <div>
                <div className="p-6">
                    <H3 className="md:text-2xl">Your involvement</H3>
                    <div className="grid grid-cols-3 justify-items-center mb-2">
                        <CampaignInvolvement
                            completed={subscribed}
                            icon={subscribed ? <Check /> : <Heart />}
                            text={subscribed ? "You're following this" : 'Follow this campagin'}
                            handleClick={toggleFollow}
                            first
                        />
                        <CampaignInvolvement
                            completed={pledged ?? false}
                            icon={pledged ? <Check /> : <DollarSign />}
                            text={pledged ? "You've pledged shares" : 'Buy and pledge shares'}
                            handleClick={() => handleOrder(campaign.targetCompaniesAsxCodes)}
                        />
                        <CampaignInvolvement
                            completed={shared}
                            icon={shared ? <Check /> : <Share />}
                            text={shared ? "You've spread the word" : 'Spread the word'}
                            handleClick={() => setShared(!shared)}
                            last
                        />
                    </div>
                    {shared && <CampaignShareModal campaign={campaign} onClose={closeShareModal} />}
                    <div>
                        {campaign.targetCompaniesAsxCodes.length > 0 && (
                            <Button
                                onClick={
                                    !subscribed
                                        ? handleFollow
                                        : !pledged
                                          ? () => handleOrder(campaign.targetCompaniesAsxCodes)
                                          : () => setShared(true)
                                }
                                tabIndex={-1}
                                variant="secondary"
                                fullWidth
                            >
                                {!subscribed ? 'Follow Campaign' : !pledged ? 'Pledge Shares' : 'Share Campaign'}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </article>
    )
}
