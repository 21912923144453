import { ReactNode, useEffect, useState } from 'react'
import { useFetchUsagePolicy } from '@/user/userQueries'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { USAGE_POLICIES } from '@/common/constants'
import useSixTermsStore from './SixTermsStore'

const SixTermsProvider = ({ children }: { children: ReactNode }) => {
    const [ready, setReady] = useState(false)

    const { setSixTerms } = useSixTermsStore()

    const { data: sixTerms } = useFetchUsagePolicy(USAGE_POLICIES.SIX_TERMS)

    useEffect(() => {
        if (!sixTerms) return
        setSixTerms(sixTerms)
        setReady(true)
    }, [sixTerms, setSixTerms])

    if (!ready) {
        return <FullScreenLoader dark />
    }

    return <>{children}</>
}

export default SixTermsProvider
