import { app } from '../common/api/apiClient'
import {
    UpdateAddressesPayload,
    ContactDetails,
    type User as UserT,
    Name,
    UsagePolicyKey,
    UsagePolicy,
} from '@/common/types'
import { API_ROUTES } from '@/common/constants'
import { SurveyT } from '@/user/survey/SurveyPage'

export async function triggerSetupUser() {
    const res = await app.put(API_ROUTES.userSetup)
    return res.data
}

export async function fetchUser() {
    const res = await app.get(API_ROUTES.user)
    return res.data as UserT
}

export const updateName = async (data: Name) => {
    const response = await app.put(API_ROUTES.userName, data)
    return response.data
}

export const updateMobile = async (data?: string) => {
    const response = await app.put(API_ROUTES.userMobile, { mobile: data })
    return response.data
}

export const sendMobileOtp = async () => {
    const response = await app.post(API_ROUTES.sendMobileOtp)
    return response.data
}

export const verifyMobileOtp = async (data: string) => {
    const response = await app.post(API_ROUTES.verifyMobileOtp, { code: data })
    return response.data
}

export const updateSurveyData = async (data: SurveyT) => {
    const response = await app.put(API_ROUTES.survey, data)
    return response.data
}

export const updateContactDetails = async (data: ContactDetails) => {
    const response = await app.put(API_ROUTES.userContactDetails, data)
    return response.data
}

export const updateAddresses = async (data: UpdateAddressesPayload) => {
    const response = await app.put(API_ROUTES.userAddresses, data)
    return response.data
}

export const searchAddress = async (query: string) => {
    const res = await app.post(API_ROUTES.addressSearch, {
        query,
    })
    return res.data
}

export const searchAddressById = async (addressId: string) => {
    const res = await app.get(`${API_ROUTES.addressSearchById}/${addressId}`)
    return res.data
}

export const getUsagePolicy = async (policyKey: UsagePolicyKey) => {
    const res = await app.get(API_ROUTES.usagePolicies.replace('{}', policyKey))
    return res.data as UsagePolicy
}

export const acceptPolicy = async (versionId: string) => {
    const res = await app.post(API_ROUTES.acceptPolicy, { versionId })
    return res.data
}
