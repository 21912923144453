import { Button } from '@/common/ui/button'
import { H3 } from '@/common/ui'
import SolidBackgroundModal from '@/common/ui/SolidBackgroundModal'

type Props = {
    title: string
    children: React.ReactNode
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
    cta?: string
    isLoading?: boolean
    onBack?: () => void
}

export function Onboarding({ title, children, onSubmit, cta = 'Next', isLoading, onBack }: Props) {
    return (
        <SolidBackgroundModal>
            <H3 className="text-center">{title}</H3>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    onSubmit(e)
                }}
                className="relative z-0 w-full max-w-xl"
            >
                <div className="py-3 xs:py-6 w-full mb-auto flex flex-col gap-4">{children}</div>
                <div className="pt-4 xs:pt-6 w-full mb-auto flex justify-between">
                    <Button loading={isLoading} type="submit" variant={'secondary'}>
                        {cta}
                    </Button>
                    {onBack && (
                        <Button onClick={onBack} variant={'ghost'}>
                            Back
                        </Button>
                    )}
                </div>
            </form>
        </SolidBackgroundModal>
    )
}
