import { ReactNode, useEffect, useState } from 'react'
import useUserStore from '@/user/userStore'
import { useFetchUser } from '@/user/userQueries'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'

const UserProvider = ({ children }: { children: ReactNode }) => {
    const [ready, setReady] = useState(false)

    const { setUser } = useUserStore()

    const { data: user } = useFetchUser(true)

    useEffect(() => {
        if (!user) return
        if (user) {
            setUser(user)
            setReady(true)
        }
    }, [user, setUser])

    if (!ready) {
        return <FullScreenLoader dark />
    }

    return <>{children}</>
}

export default UserProvider
