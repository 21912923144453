import { useMutation, useQuery } from '@tanstack/react-query'
import { initKYC, getKYC, requestKYCStatusUpdate } from '@/kyc/kycApi'
import { API_ROUTES } from '@/common/constants'
import { defaultOptions } from '@/common/utils/queryOptions'

export const useInitKYC = () => {
    return useQuery({
        queryKey: [API_ROUTES.kycInit],
        queryFn: initKYC,
        refetchOnMount: true,
    })
}

export const useFetchKYC = (options = defaultOptions) => {
    const { enabled, refetchInterval } = options
    return useQuery({
        queryKey: [API_ROUTES.kyc],
        queryFn: getKYC,
        enabled,
        refetchInterval,
    })
}

export const useRequestKYCStatusUpdate = () => {
    return useMutation({
        mutationFn: () => requestKYCStatusUpdate(),
        onError: (err) => {
            console.log(err)
        },
    })
}
