import React from 'react'
import { cn } from '@/common/utils/utils'

export interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
    label?: string
}

export const Label = ({ className, children, htmlFor }: Props) => {
    return (
        <label htmlFor={htmlFor} className={cn('text-sm mb-1', className)}>
            {children}
        </label>
    )
}
