export const occupations = [
    'Business Owner',
    'Clerical and administrative workers',
    'Community/personal service workers',
    'Employees',
    'Executives and General Managers',
    'Homemaker',
    'Labourers',
    'Machinery operators and drivers',
    'Military',
    'Professionals',
    'Retired',
    'Sales workers',
    'Student',
    'Technicians and trades workers',
]
