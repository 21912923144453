import { analytics } from '@/common/analytics/analytics'
import { useEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export const OnboardingContainer = () => {
    const location = useLocation()
    const prevLocation = useRef<string>()

    useEffect(() => {
        if (prevLocation.current !== location.pathname) {
            analytics.page()
            prevLocation.current = location.pathname
        }
    }, [location.pathname])

    return <Outlet />
}
