import { cn } from '@/common/utils/utils'

type Props = {
    children: React.ReactNode
    className?: string
}

export function H2({ children, className }: Props) {
    return <h2 className={cn('text-3xl md:text-4xl mb-4 font-medium', className)}>{children}</h2>
}
