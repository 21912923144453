import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { ColumnDef } from '@tanstack/react-table'
import { cn, debounce } from '@/common/utils/utils'
import { DataTable } from '@/common/ui/data-table'
import { InstrumentT, TopMarketDataResponse } from '@/common/types'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { getTopCompanies, searchInstruments } from '@/trading/tradingApi'
import { TOP_COMPANIES_LIST_PAGE_SIZE } from '@/common/config'
import { Button } from '@/common/ui/button'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP } from '@/common/strings'
import Money from '@/common/models/money'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'
import { CompanyHead } from '@/equities/companyHead'
import { NoTradingAccountAlert } from './noTradingAccountAlert'
import { InstrumentsSearchForm } from '@/equities/instrumentsSearchForm'
import { TradeModal } from './tradeModal'

export function Trading() {
    const [selectedTradeInstrument, setSelectedTradeInstrument] = useState<InstrumentT | null>(null)
    const [topCompanies, setTopCompanies] = useState<TopMarketDataResponse | null>(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [searchResult, setSearchResult] = useState<InstrumentT[] | null>(null)
    const { showCompanySearch, showTradingPage } = useFeatureFlags()

    // @ts-ignore
    const columns: ColumnDef<InstrumentT>[] = useMemo(
        () => [
            {
                accessorKey: 'name',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Company</span>
                },
                cell: ({ row }) => {
                    const securityCode = row.original.securityCode
                    return (
                        <Link to={`/equity/${securityCode}`} className="text-inherit">
                            <CompanyHead
                                nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                                instrument={row.original}
                            />
                        </Link>
                    )
                },
            },
            {
                accessorKey: 'priceChange',
                header: () => {
                    return <span className="font-bold text-black">Change</span>
                },
                cell: ({ row }) => {
                    const prices = row.original.priceInfo
                    const price = prices.lastPrice || prices.closingPrice
                    return (
                        <div
                            className={cn('flex flex-col', {
                                'text-success': prices?.pointsChange >= 0,
                                'text-destructive': prices?.pointsChange < 0,
                            })}
                        >
                            <span className="mb-0 font-bold">{`${Money.of(price)}`}</span>
                            {prices.percentageChange && (
                                <span className="flex gap-2">
                                    <span className={cn('flex gap-1')}>
                                        <span
                                            className={cn('size-0 border-transparent border-[7px]', {
                                                'border-b-success': prices.pointsChange >= 0,
                                                'border-t-destructive mt-2': prices.pointsChange < 0,
                                            })}
                                        />
                                        ({prices.percentageChange})%
                                    </span>
                                </span>
                            )}
                        </div>
                    )
                },
                enableSorting: false,
            },
            {
                accessorKey: 'buyPrice',
                header: () => {
                    return <span className="font-bold text-black">Buy</span>
                },
                cell: ({ row }) => {
                    const prices = row.original.priceInfo
                    return (
                        <span className="flex items-center gap-2">
                            <strong>B</strong>{' '}
                            <span className="bg-secondary-light py-1 px-4 min-w-24">{`${Money.of(prices.buyPrice)}`}</span>
                        </span>
                    )
                },
                enableSorting: false,
            },
            {
                accessorKey: 'sellPrice',
                header: () => {
                    return <span className="font-bold text-black">Sell</span>
                },
                cell: ({ row }) => {
                    const prices = row.original.priceInfo
                    return (
                        <span className="flex items-center gap-2">
                            <strong>S</strong>
                            <span className="bg-destructive-light py-1 px-4 min-w-24">{`${Money.of(prices.sellPrice)}`}</span>
                        </span>
                    )
                },
                minSize: '100px',
                maxSize: '500px',
                enableSorting: false,
            },
            {
                accessorKey: 'securityCode',
                header: () => {
                    return <span className="font-bold text-black">Trade</span>
                },
                cell: ({ row }) => {
                    return (
                        <Button
                            className="px-5"
                            onClick={() => setSelectedTradeInstrument(row.original)}
                            variant="tertiary"
                            size="sm"
                        >
                            Place Order
                        </Button>
                    )
                },
                enableSorting: false,
            },
        ],
        [],
    )

    const fetchInstruments = useCallback((query: string) => {
        searchInstruments(query).then((res) => {
            setSearchResult(res.companies)
        })
    }, [])

    useEffect(() => {
        getTopCompanies(pageNumber).then((data) => setTopCompanies(data))
    }, [pageNumber])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceOnChange = useCallback(debounce(fetchInstruments, 500), [])

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value
        setSearchTerm(query)
        if (query.length === 0) onInputClear()
        if (query.length < 3) return
        debounceOnChange(query)
    }

    const onInputClear = () => {
        setSearchTerm('')
        setSearchResult(null)
    }

    const onTradeModalClose = () => {
        setSelectedTradeInstrument(null)
    }

    if (!topCompanies) return <FullScreenLoader />

    const totalPages = Math.ceil(topCompanies.totalCount / TOP_COMPANIES_LIST_PAGE_SIZE)

    const prevPage = () => {
        setPageNumber((n) => (n === 1 ? n : n - 1))
    }

    const nextPage = () => {
        setPageNumber((n) => (n === totalPages ? n : n + 1))
    }

    if (!showTradingPage) return null

    return (
        <div>
            <Helmet>
                <title>Trading | {APP.title}</title>
            </Helmet>
            <NoTradingAccountAlert />
            {showCompanySearch && (
                <InstrumentsSearchForm value={searchTerm} onChange={onSearchChange} onClear={onInputClear} />
            )}
            <div className="mt-6">
                <DataTable columns={columns} data={searchResult ? searchResult : topCompanies.companies} />
            </div>
            {!searchResult && (
                <div className="mt-6 flex justify-between">
                    <Button onClick={prevPage} variant="outline" className="flex space-x-3">
                        <ChevronLeft size={16} />
                        Prev
                    </Button>
                    <span>{`${topCompanies.page} / ${totalPages}`}</span>
                    <Button onClick={nextPage} variant="outline" className="flex space-x-3">
                        Next
                        <ChevronRight size={16} />
                    </Button>
                </div>
            )}
            <TradeModal
                onClose={onTradeModalClose}
                instruments={selectedTradeInstrument ? [selectedTradeInstrument] : []}
            />
        </div>
    )
}
