import { cn } from '@/common/utils/utils'

type Props = {
    completed: boolean
    icon: React.ReactNode
    text: string
    handleClick: () => void
    first?: boolean
    last?: boolean
}

export const CampaignInvolvement = ({ completed, icon, text, handleClick, first, last }: Props) => {
    return (
        <div id="involvement" className="relative w-full flex flex-col items-center p-1">
            <div className="absolute w-full h-[1px] top-[38px]">
                <div
                    className={cn(
                        'h-[1px] bg-dark-green',
                        first ? 'w-[50%]  ml-[50%]' : last ? 'w-[50%] ml-0' : 'w-[100%] ml-0',
                    )}
                />
            </div>
            <div
                onClick={handleClick}
                className={cn(
                    'h-[70px] w-[70px] rounded-full border bg-white border-dark-green mb-2 z-10',
                    'flex items-center justify-center',
                    'cursor-pointer',
                    completed ? 'bg-dark-green text-electric-lime' : 'text-dark-green',
                )}
            >
                {icon}
            </div>
            <div className="text-center text-xs">{text}</div>
        </div>
    )
}
