const SolidBackgroundModal = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="bg-primary min-h-screen flex justify-center items-center p-3 xs:p-6">
            <main className="w-full max-w-[600px] py-14 px-6 xs:py-14 xs:px-10 bg-white m-auto rounded rounded-tl-modal rounded-br-modal">
                {children}
            </main>
        </div>
    )
}

export default SolidBackgroundModal
