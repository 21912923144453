import { CampaignWithSubscription } from '@/campaigns/Campaign'
import { create } from 'zustand'

interface State {
    currentCampaign: CampaignWithSubscription | null
    setCurrentCampaign: (currentCampaign: CampaignWithSubscription | null) => void
    unsetCurrentCampaign: () => void
}

const useCampaignStore = create<State>((set) => ({
    currentCampaign: null,
    setCurrentCampaign: (currentCampaign: CampaignWithSubscription | null) => set({ currentCampaign }),
    unsetCurrentCampaign: () => set({ currentCampaign: null }),
}))

export default useCampaignStore
