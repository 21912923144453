import { FC } from 'react'
import { Tooltip, XAxis, YAxis, Area, AreaChart, ResponsiveContainer } from 'recharts'

import { HistoricalPricesRangePreset, PriceDataPoint } from '@/common/types'
import { MONTHS } from '@/common/constants'
import { Maybe } from '@/common/types'

type Props = {
    data: Maybe<PriceDataPoint[]>
    rangePreset: HistoricalPricesRangePreset
}

const getDayTime = (datestring: string) => {
    const date = new Date(datestring)
    return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
}

const getDayMonth = (datestring: string) => {
    const date = new Date(datestring)
    return date.getDate() + ' ' + MONTHS[date.getMonth()]
}

const getMonthYear = (datestring: string) => {
    const date = new Date(datestring)
    return MONTHS[date.getMonth()] + ' ' + date.getFullYear().toString().substring(2)
}

const getDayMonthYear = (datestring: string) => {
    const date = new Date(datestring)
    return date.getDate() + ' ' + MONTHS[date.getMonth()] + ' ' + date.getFullYear()
}

const placeholderData = [
    {
        close: 14,
        date: new Date().toISOString(),
    },
    {
        close: 16,
        date: new Date().toISOString(),
    },
    {
        close: 14,
        date: new Date().toISOString(),
    },
    {
        close: 16,
        date: new Date().toISOString(),
    },
    {
        close: 14,
        date: new Date().toISOString(),
    },
    {
        close: 16,
        date: new Date().toISOString(),
    },
]

export const HistoricalPriceChart: FC<Props> = ({ data = [], rangePreset }) => {
    const lowerstPrice = data?.reduce((lowest, d) => (d.close < lowest ? d.close : lowest), Number.POSITIVE_INFINITY)
    const highestPrice = data?.reduce((lowest, d) => (d.close > lowest ? d.close : lowest), Number.NEGATIVE_INFINITY)

    const isRangeLessThanYear =
        rangePreset === '1D' ||
        rangePreset === '1W' ||
        rangePreset === '1M' ||
        rangePreset === '6M' ||
        rangePreset === 'YTD' ||
        rangePreset === '1Y'

    return (
        <div>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={data || placeholderData} margin={{ top: 10, right: 30, left: -30, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#E4FBC6" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#E4FBC6" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        tickSize={0}
                        minTickGap={rangePreset === '1D' ? 500 : 60}
                        tickFormatter={isRangeLessThanYear ? getDayMonth : getMonthYear}
                        tickMargin={6}
                        padding="gap"
                        dataKey="date"
                        interval="equidistantPreserveStart"
                        strokeOpacity={0.4}
                    />
                    <YAxis
                        tickSize={0}
                        strokeWidth={1}
                        tickMargin={6}
                        strokeOpacity={0.2}
                        tickCount={4}
                        padding={{ bottom: 40 }}
                        dataKey="close"
                        type="number"
                        domain={[
                            Math.max(1, Math.floor((lowerstPrice || 10) - 10)),
                            Math.ceil((highestPrice || -10) + 10),
                        ]}
                    />
                    <Tooltip
                        labelFormatter={
                            rangePreset === '1D' ? getDayTime : isRangeLessThanYear ? getDayMonth : getDayMonthYear
                        }
                    />
                    {!data ||
                        (data?.length === 0 && (
                            <text x="50%" y="50%" style={{ fill: '#32871D' }} textAnchor="middle">
                                Loading
                            </text>
                        ))}
                    {data && data?.length > 0 && (
                        <Area
                            strokeWidth={2}
                            type="linear"
                            dataKey="close"
                            stroke="#32871D"
                            fillOpacity={1}
                            fill="url(#colorClose)"
                        />
                    )}
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}
