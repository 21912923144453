import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { SearchableAddressForm } from './SearchableAddressForm'
import { Checkbox } from '@/common/ui/checkbox'
import { Onboarding } from '@/onboarding/OnboardingPageTemplate'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { AddressDetails, AddressFormSchema } from '@/common/types'
import { useUpdateAddresses } from '@/user/userQueries'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP_ROUTES } from '@/common/constants'
import { APP } from '@/common/strings'
import { Label } from '@/common/ui'
import useUserStore from '@/user/userStore'
import { analytics } from '@/common/analytics/analytics'

const schema = yup
    .object({
        residential: yup.object({
            addressPrefix: yup.string().optional().nullable(),
            streetNumber: yup.string().required('Please enter street number'),
            streetName: yup.string().required('Please enter street name'),
            streetType: yup.string().optional(),
            city: yup.string().required('Please enter suburb'),
            state: yup.string().required('Please enter state'),
            postCode: yup.string().required('Please enter postal code'),
            country: yup.string().required('Please enter country'),
        }),
        differentPostalAddress: yup.boolean(),
        postal: yup.object().when('differentPostalAddress', {
            is: true,
            then: () =>
                yup.object({
                    addressPrefix: yup.string().optional().nullable(),
                    streetNumber: yup.string().required('Please enter street number'),
                    streetName: yup.string().required('Please enter street name'),
                    streetType: yup.string().required('Please enter street type'),
                    city: yup.string().required('Please enter suburb'),
                    state: yup.string().required('Please enter state'),
                    postCode: yup.string().required('Please enter postal code'),
                    country: yup.string().required('Please enter country'),
                }),
        }),
    })
    .required()

export function Address() {
    const {
        register,
        handleSubmit,
        control,
        trigger,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
    })
    const [differentPostalAddress, setDifferentPostalAddress] = useState(false)
    const updateAddresses = useUpdateAddresses()
    const { user, setUser } = useUserStore()
    const navigate = useNavigate()

    const onSubmit = (data: AddressFormSchema) => {
        const residentialAddress = data.residential
        const postalAddress = data.differentPostalAddress ? (data.postal as AddressDetails) : data.residential
        const addresses = {
            residentialAddress,
            postalAddress,
        }
        updateAddresses.mutate({ addresses })
        if (!user) return
        setUser({ ...user, addresses })
    }

    const handleBack = () => {
        navigate(APP_ROUTES.NAME)
    }

    useEffect(() => {
        if (!updateAddresses.isSuccess) return
        analytics.track('Address Updated')
        navigate(APP_ROUTES.MOBILE_NUMBER)
    }, [updateAddresses.isSuccess, navigate])

    if (!user) {
        return <FullScreenLoader />
    }

    return (
        <>
            <Helmet>
                <title>Your Address | {APP.title}</title>
            </Helmet>
            <Onboarding
                isLoading={updateAddresses.isPending}
                title="Your Address"
                cta="Next"
                onSubmit={handleSubmit(onSubmit)}
                onBack={handleBack}
            >
                <div>
                    <Label>Residential Address</Label>
                    <SearchableAddressForm
                        type="residential"
                        defaultValues={user?.addresses?.residentialAddress}
                        register={register}
                        setValue={setValue}
                        trigger={trigger}
                        errors={errors?.residential}
                    />
                    <div className="flex leading-none space-x-3 mt-2 items-center">
                        <Controller
                            name="differentPostalAddress"
                            control={control}
                            defaultValue={false}
                            render={({ field: { onChange } }) => {
                                return (
                                    <Checkbox
                                        id="different-postal-address"
                                        onCheckedChange={(e) => {
                                            onChange(e)
                                            setDifferentPostalAddress((val) => !val)
                                        }}
                                        checked={differentPostalAddress}
                                    />
                                )
                            }}
                        />

                        <label
                            htmlFor="different-postal-address"
                            className="text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            Add different postal address
                        </label>
                    </div>
                </div>

                {differentPostalAddress && (
                    <div className="my-8 w-full">
                        <Label>Postal address</Label>
                        <SearchableAddressForm
                            type="postal"
                            defaultValues={user?.addresses?.postalAddress}
                            register={register}
                            setValue={setValue}
                            trigger={trigger}
                            errors={errors}
                        />
                    </div>
                )}
            </Onboarding>
        </>
    )
}
