import { FC, useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/ui/tabs'
import { LoginTab } from './LoginTab'
import { CreateAccountTab } from './CreateAccountTab'
import useAuthStore from '@/auth/authStore'
import ForgotPassword from './forgotPassword'
import { Logo } from '@/common/assets/svg'
import { H3 } from '@/common/ui'
import SolidBackgroundModal from '@/common/ui/SolidBackgroundModal'
import { usePreloadCampaignsAndImages } from '@/campaigns/usePreloadCampaignsAndImages'

type TabProps = {
    label: string
    value: string
}
const Tab: FC<TabProps> = ({ label, value }) => {
    return (
        <TabsTrigger
            value={value}
            className="data-[state=active]:font-semibold text-foreground w-full py-3 rounded-b-none data-[state=active]:bg-secondary-light"
        >
            {label}
        </TabsTrigger>
    )
}

type AuthTab = 'login' | 'signup'

export const AuthScreen = () => {
    const [activeTab, setActiveTab] = useState<AuthTab>('login')
    const { isForgotPassword, setIsForgotPassword } = useAuthStore()
    const defaultTab = (location.hash.slice(1) as AuthTab) || 'login'

    const handleTabChange = (value: string) => {
        setActiveTab(value as AuthTab)
    }

    const hideResetPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsForgotPassword(false)
    }

    // Preload campaigns while user is on the auth screen
    usePreloadCampaignsAndImages()

    return (
        <SolidBackgroundModal>
            <Logo className="h-[34px] md:h-[40px] mx-auto mb-8" />
            {isForgotPassword ? (
                <ForgotPassword onSignInClick={hideResetPassword} />
            ) : (
                <>
                    {activeTab === 'login' && (
                        <H3 className="hidden text-center mb-8 md:block">Login to your account</H3>
                    )}
                    {activeTab === 'signup' && (
                        <>
                            <H3 className="hidden text-center mb-4 md:block">Create an account</H3>
                            <p className="hidden md:block text-sm text-center mb-8">
                                Please note, SIX is currently only offered to AU and NZ citizens.
                            </p>
                        </>
                    )}

                    <Tabs defaultValue={defaultTab} onValueChange={handleTabChange} className="mt-4">
                        <TabsList className="p-0 h-auto bg-background flex justify-center">
                            <Tab label="Log in" value="login" />
                            <Tab label="Create an account" value="signup" />
                        </TabsList>
                        <div className="h-[2px] bg-primary-light" />
                        <TabsContent tabIndex={-1} className="pt-4" value="login">
                            <LoginTab />
                        </TabsContent>
                        <TabsContent tabIndex={-1} className="pt-4" value="signup">
                            <CreateAccountTab />
                        </TabsContent>
                    </Tabs>
                </>
            )}
        </SolidBackgroundModal>
    )
}
