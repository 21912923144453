import axios from 'axios'
import { SST_API_URL } from '@/common/env'

export const app = axios.create({
    baseURL: SST_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})
