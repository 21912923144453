import { API_ROUTES } from '@/common/constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
    getAccountDetails,
    getBalance,
    getPayId,
    getTransactions,
    saveAccountDetails,
    saveWithdrawalRequest,
} from './walletApi'
import { toast } from 'sonner'
import { AccountDetails, WithdrawRequest } from './types'
import { defaultOptions } from '@/common/utils/queryOptions'

export const useFetchBalance = (options = defaultOptions) => {
    const { enabled, refetchInterval } = options
    return useQuery({
        queryKey: [API_ROUTES.balance],
        queryFn: getBalance,
        enabled,
        refetchOnMount: true,
        refetchInterval,
    })
}

export const useFetchTransactions = (options = defaultOptions) => {
    const { enabled, refetchInterval } = options
    return useQuery({
        queryKey: [API_ROUTES.transactions],
        queryFn: getTransactions,
        enabled,
        refetchOnMount: true,
        refetchInterval,
    })
}

export const useFetchPayId = (enabled = true) => {
    return useQuery({
        queryKey: [API_ROUTES.payId],
        queryFn: getPayId,
        enabled,
    })
}

export const useFetchAccountDetails = (isEnabled = true) => {
    return useQuery({
        queryKey: [API_ROUTES.accountDetails],
        queryFn: getAccountDetails,
        enabled: isEnabled,
        refetchOnMount: true,
    })
}

export const useSaveAccountDetails = () => {
    return useMutation({
        mutationFn: (accountDetails: AccountDetails) => saveAccountDetails(accountDetails),
        onSuccess: () => {},
        onError: (err: any) => {
            console.log('Failed to save account details', err)
            toast.error('Failed to save account details. Please try again')
        },
    })
}

export const useSaveWithdrawRequest = () => {
    return useMutation({
        mutationFn: (withdrawRequest: WithdrawRequest) => saveWithdrawalRequest(withdrawRequest),
        onSuccess: () => {},
        onError: (err: any) => {
            console.log('Failed to withdraw', err)
            toast.error('Failed to withdraw. Please try again')
        },
    })
}
