import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet'
import { APP } from '@/common/strings'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/common/ui/input-otp'
import { APP_ROUTES } from '@/common/constants'
import { Onboarding } from '@/onboarding/OnboardingPageTemplate'
import { useEffect, useState } from 'react'
import { useVerifyMobileOtp } from '@/user/userQueries'
import { analytics } from '@/common/analytics/analytics'
import useUserStore from '../userStore'

export function MobileVerification() {
    const navigate = useNavigate()
    const verifyMobileOtp = useVerifyMobileOtp()
    const [otp, setOtp] = useState('')
    const { user } = useUserStore()

    const handleBack = () => {
        navigate(APP_ROUTES.MOBILE_NUMBER)
        analytics.track('Mobile VerificationBack')
    }

    const handleChange = (str: string) => {
        // remove non-numeric characters
        str = str.replace(/\D/g, '')
        setOtp(str)
    }

    const handleSubmit = () => {
        verifyMobileOtp.mutate(otp)
    }

    useEffect(() => {
        if (!verifyMobileOtp.isIdle) return
        if (otp.length === 6) {
            console.log('submitting')
            verifyMobileOtp.mutate(otp)
        }
    }, [otp, verifyMobileOtp])

    useEffect(() => {
        if (!verifyMobileOtp.isSuccess) return
        analytics.track('Mobile Verified')
        navigate(APP_ROUTES.SURVEY)
    }, [verifyMobileOtp.isSuccess, navigate])

    useEffect(() => {
        if (!verifyMobileOtp.isError) return
        analytics.track('Mobile VerificationFailed')
    }, [verifyMobileOtp.isError])

    return (
        <>
            <Helmet>
                <title>Mobile Verification | {APP.title}</title>
            </Helmet>
            <Onboarding
                title="Your Mobile Number"
                cta="Next"
                isLoading={verifyMobileOtp.isPending}
                onBack={handleBack}
                onSubmit={handleSubmit}
            >
                <p className="text-center text-balance mb-6">
                    To confirm your identity, please enter the 6-digit code we sent to your mobile number:{' '}
                    {user?.mobile}
                </p>
                <div className="w-full grid place-items-center">
                    <InputOTP maxLength={6} onChange={handleChange} value={otp}>
                        <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                        </InputOTPGroup>
                    </InputOTP>
                </div>
            </Onboarding>
        </>
    )
}
