import { DataTable } from '@/common/ui/data-table'
import { Transaction } from '@/wallet/walletApi'
import { useFetchTransactions } from '@/wallet/walletQueries'
import { H2 } from '@/common/ui/h2'
import { cn, formatDate, seconds } from '@/common/utils/utils'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import Money from '@/common/models/money'
import useKycStore from '@/kyc/kycStore'

export default function Transactions() {
    const { kycComplete } = useKycStore()
    const { data: transactions, isLoading } = useFetchTransactions({
        enabled: kycComplete,
        refetchInterval: seconds(10),
    })

    const HeaderText = ({ text, right = false }: { text: string; right?: boolean }) => (
        <div className={`text-black font-bold w-full ${right && 'text-right'}`}>{text}</div>
    )
    const ItemText = ({ text, right = false }: { text: string; right?: boolean }) => (
        <div className={cn('text-black w-full py-2', right && 'text-right')}>{text}</div>
    )

    const columns: ColumnDef<Transaction>[] = useMemo(
        () => [
            {
                accessorKey: 'date',
                enableSorting: false,
                header: () => <HeaderText text="Date" />,
                cell: ({ row }) => <ItemText text={formatDate(row.original.datetime)} />,
            },
            {
                accessorKey: 'description',
                enableSorting: false,
                header: () => <HeaderText text="Description" />,
                cell: ({ row }) => <ItemText text={row.original.description} />,
            },
            {
                accessorKey: 'amount',
                enableSorting: false,
                header: () => <HeaderText text="Amount" right />,
                cell: ({ row }) => <ItemText text={`${Money.of(row.original.amount)}`} right />,
            },
            {
                accessorKey: 'balance',
                enableSorting: false,
                header: () => <HeaderText text="Balance" right />,
                cell: ({ row }) => <ItemText text={`${Money.of(row.original.runningBalance)}`} right />,
            },
        ],
        [],
    )

    return (
        <section>
            <H2 className="mb-6">Transactions</H2>
            <div className="max-w-[80vw] xs:max-w-full overflow-x-auto">
                <DataTable
                    columns={columns}
                    data={transactions || []}
                    noDataMessage={isLoading ? 'Loading transactions...' : 'No transactions yet...'}
                />
            </div>
        </section>
    )
}
