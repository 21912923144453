import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signIn, signUp } from 'aws-amplify/auth'
import * as yup from 'yup'

import { LabelInput } from '@/common/ui/LabelInput'
import { type SignupForm as SignupFormT } from '@/common/types'
import { Button } from '@/common/ui/button'
import { Checkbox } from '@/common/ui/checkbox'
import { SocialSignIn } from './socialSignIn'
import { toast } from 'sonner'
import { useEffect, useState } from 'react'
import { InputError } from '@/common/ui/InputError'
import { PasswordInput } from '@/common/ui/PasswordInput'
import { cn } from '@/common/utils/utils'
import { OrDivider } from './OrDivider'
import useSixTermsStore from '@/user/policies/SixTermsStore'

const schema = yup
    .object({
        email: yup.string().email('Please enter valid email').required(),
        password: yup
            .string()
            .min(8, 'Password should be at least 8 characters long')
            .matches(/^.*[a-z]+.*$/, 'Must contain at least 1 lower case letter')
            .matches(/^.*[A-Z]+.*$/, 'Must contain at least 1 upper case letter')
            .matches(/^.*[0-9]+.*$/, 'Must contain at least 1 number')
            .max(50, 'Password can not be longer than 50 characters')
            .required(),
        confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
        tnc: yup.boolean().oneOf([true], 'Please accept the terms and conditions'),
    })
    .required()

export function CreateAccountTab() {
    const [isRunning, setIsRunning] = useState(false)
    const [isTncAccepted, setIsTncAccepted] = useState(false)
    const [signupWithEmail, setSignupWithEmail] = useState(false)
    const { sixTerms, setUserAcceptedSixTerms } = useSixTermsStore()
    const {
        register,
        handleSubmit,
        control,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })

    const onSubmit = async (data: SignupFormT) => {
        const { email, password } = data
        try {
            setIsRunning(true)
            const { isSignUpComplete } = await signUp({
                username: email,
                password,
                options: {
                    userAttributes: {
                        email,
                    },
                    autoSignIn: false,
                },
            })
            if (isSignUpComplete) {
                await signIn({ username: email, password })
            }
        } catch (error: any) {
            console.log('error signing in', error)
            if (error.message.includes('already exists')) {
                toast.error('Email already exists', {
                    id: 'email-exists',
                })
                return
            }
            toast.error(error.message)
        } finally {
            setIsRunning(false)
        }
    }

    useEffect(() => {
        setUserAcceptedSixTerms(isTncAccepted)
    }, [isTncAccepted, setUserAcceptedSixTerms])

    return (
        <>
            <form noValidate className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="flex gap-3">
                        <Controller
                            name="tnc"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => {
                                // @ts-ignore
                                const onChange = (checked) => {
                                    field.onChange()

                                    setIsTncAccepted(checked as boolean)
                                    setValue('tnc', checked as boolean)
                                    trigger('tnc')
                                }
                                return <Checkbox onCheckedChange={onChange} {...register('tnc')} id="tnc" />
                            }}
                        />
                        <label className="text-sm" htmlFor="tnc">
                            {sixTerms?.disclaimer && (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: sixTerms.disclaimer.replace('Privacy Policy', 'Privacy&nbsp;Policy'),
                                    }}
                                />
                            )}
                        </label>
                    </div>
                    <InputError message={errors.tnc?.message} />
                </div>
                <SocialSignIn isTncAccepted={isTncAccepted} onClick={() => trigger('tnc')} />

                {!signupWithEmail && (
                    <Button variant="outline" className="mb-4" onClick={() => setSignupWithEmail(true)}>
                        Signup with email address
                    </Button>
                )}

                <div className={cn('hidden flex-col gap-4', signupWithEmail && 'flex')}>
                    <OrDivider />
                    <LabelInput
                        {...register('email')}
                        error={errors.email ? 'Please enter a valid email address' : undefined}
                        required
                        type="email"
                        id="email"
                        label="Email"
                    />
                    <PasswordInput
                        {...register('password')}
                        error={errors.password?.message}
                        required
                        id="password"
                        label="Password"
                    />
                    <PasswordInput
                        {...register('confirmPassword')}
                        error={errors.confirmPassword?.message}
                        required
                        id="confirm-password"
                        label="Confirm password"
                    />
                    <Button loading={isRunning} variant="secondary" type="submit" className="w-max mt-2">
                        Create account
                    </Button>
                </div>
            </form>
        </>
    )
}
