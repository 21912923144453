import { Button, LabelInput } from '@/common/ui'
import { DialogDescription, DialogHeader, DialogTitle } from '@/common/ui/dialog'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { TriangleAlert, Undo2 as Back, SquarePen } from 'lucide-react'
import { AccountDetails } from './types'
import { useEffect, useState } from 'react'
import Money from '@/common/models/money'

const schema = yup
    .object({
        amount: yup.number().required('Please enter amount').min(2, 'Please enter a valid amount'),
    })
    .required()

type Props = {
    accountDetails: AccountDetails
    handleBack: () => void
    handleEdit: () => void
    onWithdrawRequest: (amount: number) => void
    savingWithdrawRequest: boolean
    availableBalance: number
}

export default function WithdrawForm(props: Props) {
    const [overDrawing, setOverDrawing] = useState<boolean>(false)
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    })

    useEffect(() => {
        const subscription = watch(({ amount }) => {
            amount && amount > props.availableBalance ? setOverDrawing(true) : setOverDrawing(false)
        })

        return () => subscription.unsubscribe()
    }, [watch, props.availableBalance])

    const onSubmit: SubmitHandler<{ amount: number }> = async (data) => {
        props.onWithdrawRequest(data.amount)
    }

    return (
        <DialogHeader>
            <DialogTitle className="text-center text-3xl">Withdraw funds</DialogTitle>
            <DialogDescription className="text-center">
                Withdraw available funds from your SIX account and into your personal bank account.
            </DialogDescription>
            <div className="w-[80%] m-auto">
                <form noValidate onSubmit={handleSubmit(onSubmit)} className="relative z-0 w-full max-w-xl">
                    <div className="py-6 w-full mb-auto">
                        <LabelInput
                            className="h-20"
                            {...register('amount')}
                            type="text"
                            id="amount"
                            required
                            formNoValidate
                            label="Amount($)"
                            placeholder="Enter amount"
                            error={errors.amount?.message}
                        />
                    </div>
                    {overDrawing && (
                        <div className="bg-[#fee2e2] p-4 rounded-tl-3xl rounded-br-3xl p-4 flex">
                            <TriangleAlert width={60} height={24} className="self-start text-destructive" />
                            <p className="text-base ml-2 text-[#33404F]">
                                You have insufficient funds available for withdrawal. Only settled and cleared funds can
                                be withdrawn.
                            </p>
                        </div>
                    )}
                    <div className="py-4">
                        <span className="font-bold">Available to withdraw:</span>
                        <span> {`${Money.of(props.availableBalance)}`}</span>
                    </div>
                    <div>
                        <div className="bg-accent rounded-tl-3xl rounded-br-3xl p-4">
                            <div className="font-bold text-lg flex justify-between">
                                <span>Nominated bank account</span>
                                <Button variant="ghost" onClick={props.handleEdit} type="button">
                                    <SquarePen />
                                </Button>
                            </div>
                            <div className="text-base">
                                <span className="block">Name: {props.accountDetails.bankAccountName}</span>
                                <span className="block">BSB: {props.accountDetails.bsb}</span>
                                <span className="block">Account no: {props.accountDetails.bankAccountNumber}</span>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 w-full mb-auto flex justify-between">
                        <Button
                            type="submit"
                            disabled={overDrawing}
                            variant={'secondary'}
                            loading={props.savingWithdrawRequest}
                        >
                            Withdraw
                        </Button>
                        <Button onClick={props.handleBack} variant={'ghost'} type="button">
                            <div className="bg-accent rounded-full p-1">
                                <Back />
                            </div>
                            Back
                        </Button>
                    </div>
                </form>
            </div>
        </DialogHeader>
    )
}
