import { SVGProps } from 'react'
export const Logo = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 147 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_41_302)">
            <path
                d="M19.5404 -0.00634766H0.5V0.150696C0.5 9.45873 5.44316 18.0182 13.4048 22.7225C20.9807 27.1994 26.0686 35.4972 26.0686 44.9937H45.109V44.8372C45.109 35.5292 40.1658 26.9697 32.2036 22.2648C24.6283 17.7884 19.5404 9.49014 19.5404 -0.00634766Z"
                fill="#203A32"
            />
            <path
                d="M33.189 -0.00634766H45.109V2.85417C45.109 6.81807 41.9187 10.0369 37.9886 10.0369H26.0686V7.17637C26.0686 3.21247 29.2589 -0.00634766 33.189 -0.00634766Z"
                fill="#203A32"
            />
            <path
                d="M7.62039 34.9509H19.5404V37.8114C19.5404 41.7753 16.3501 44.9942 12.42 44.9942H0.5V42.1336C0.5 38.1697 3.69031 34.9509 7.62039 34.9509Z"
                fill="#203A32"
            />
            <path
                d="M86.5719 30.4003C86.5719 36.5088 81.0671 42.7335 73.501 42.7335C68.815 42.7178 64.1492 42.1088 59.6131 40.9223L60.2301 31.3624C64.7829 35.4141 68.7349 41.4929 73.7594 41.5103C77.449 41.5237 79.6291 38.8929 79.6291 35.4618C79.6291 32.9014 77.8025 29.8769 75.8011 28.4803C72.5491 26.2119 67.4831 24.7572 63.5426 22.4888C60.1032 20.5107 57.8655 19.0565 57.8655 14.5186C57.8655 7.88724 63.3691 2.24414 70.6244 2.24414C74.5735 2.24414 79.0733 3.15267 81.1525 3.62671C81.8334 3.78201 82.3126 4.3968 82.3039 5.10059L82.195 13.5856H81.8825C80.5061 9.45535 74.246 3.80527 69.9873 3.62671C66.6136 3.48769 64.2455 5.15585 64.2455 8.81962C64.2455 12.4834 66.6851 14.5785 69.9371 16.0919C73.6267 17.8374 78.3174 18.9431 81.8196 21.3273C84.8969 23.2787 86.7091 26.7366 86.5736 30.3998H86.5719V30.4003Z"
                fill="#203A32"
            />
            <path d="M94.3486 42.7334V3.60742H102.82V42.734H94.3486V42.7334Z" fill="#203A32" />
            <path
                d="M146.5 42.7432H136.121L125.156 24.3981L121.908 28.971C117.16 35.7954 122.323 39.4993 122.643 42.7251H111.782C110.715 42.7251 110.184 41.417 110.948 40.6661C113.931 37.7323 117.144 33.9638 121.11 28.3737L124.459 23.5949L111.504 3.59741H122.102L131.92 19.3489L133.337 17.3527C138.085 10.5277 132.922 6.82379 132.602 3.59741H143.877C144.8 3.59741 145.25 4.73045 144.585 5.37608C141.52 8.34886 138.229 12.1784 134.135 17.9483L132.501 20.2807L146.5 42.7426V42.7432Z"
                fill="#203A32"
            />
        </g>
        <defs>
            <clipPath id="clip0_41_302">
                <rect width="146" height="45" fill="white" transform="translate(0.5 -0.00634766)" />
            </clipPath>
        </defs>
    </svg>
)
