import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AppNav } from '@/common/navigation/AppNav'
import { TopNavMobile } from '@/common/navigation/TopNav'
import useUserStore from '@/user/userStore'
import { useEffect, useState } from 'react'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { analytics } from '@/common/analytics/analytics'
import ProfileDropdown from '../navigation/ProfileDropdown'
import { Logo } from '../assets/svg'
import { Footer } from './Footer'

export const AppContainer = () => {
    const [ready, setReady] = useState(false)

    const { user } = useUserStore()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (!user) return

        if (!user.salutation) {
            navigate('/onboarding/name')
            return
        }

        if (!user.addresses?.residentialAddress) {
            navigate('/onboarding/address')
            return
        }

        if (!user.mobile) {
            navigate('/onboarding/mobile-number')
            return
        }

        if (!user.occupation) {
            navigate('/onboarding/survey')
            return
        }

        setReady(true)
    }, [user, navigate])

    useEffect(() => {
        if (!ready) return
        analytics.page()
    }, [location, ready])

    if (!ready) return <FullScreenLoader dark />

    return (
        <div className="relative h-screen">
            <div className="h-[80px] w-full flex justify-between items-center px-6 md:px-12">
                <NavLink to="/">
                    <Logo width={100} height={30} />
                </NavLink>
                <div className="flex items-center">
                    <ProfileDropdown user={user!} />
                    <TopNavMobile />
                </div>
            </div>
            <div className="flex relative z-0">
                <div className="hidden md:block min-w-[260px] w-1/4 max-w-[330px]">
                    <AppNav />
                </div>
                <main className="relative w-full h-[calc(100vh-80px)] overflow-y-scroll">
                    <div className="max-w-screen-xl px-6 py-8 md:py-6 md:pl-8 md:pr-12">
                        <Outlet />
                        <Footer />
                    </div>
                </main>
            </div>
        </div>
    )
}
