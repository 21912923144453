import { Button, H1 } from '@/common/ui'
import useTradeModalStore from '@/trading/tradeModalStore'

type Props = {
    followers: number
    followersGoal: number
    targetCompaniesAsxCodes: string[]
    className?: string
}

export function TakeActionBox({ followers, followersGoal, targetCompaniesAsxCodes, className }: Props) {
    console.log('targetCompaniesAsxCodes', targetCompaniesAsxCodes)
    console.log('followers', followers)

    const { setSecurityCodes } = useTradeModalStore()

    return (
        <aside className={`bg-primary w-full rounded-md p-20 text-white flex flex-col items-center ${className}`}>
            <H1 className="text-center">Take action now</H1>
            <p className="text-center font-bold">
                Goal: {followersGoal} shareholders to invest in each company before 24 June 2024
            </p>
            <p className="text-xs text-center mt-8">
                Remember, we are after a minimum pledge of AU$500+fees per company.
            </p>
            <p className="text-xs text-center">You own these shares outright until you sell them.</p>
            <Button onClick={() => setSecurityCodes(targetCompaniesAsxCodes)} className="mt-8" variant="secondary">
                Buy shares
            </Button>
        </aside>
    )
}
