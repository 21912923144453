import { FrankieOneKYCSmartUI } from '@/kyc/FrankieOneKYCSmartUI'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import { useRef } from 'react'
import { useClickAway } from 'react-use'

type Props = {
    open: boolean
    onClose: () => void
}

export default function KycModal(props: Props) {
    const { open, onClose } = props
    const kycModalRef = useRef(null)
    useClickAway(kycModalRef, () => {
        handleKycModalClose()
    })

    const handleKycModalClose = () => {
        onClose()
        window.location.reload()
    }

    return (
        <Dialog open={open}>
            <div className="absolute py-10 h-screen">
                <DialogContent
                    ref={kycModalRef}
                    onClose={onClose}
                    className="p-0 w-[400px] sm:w-[600px] lg:w-[800px] xl:w-[1000px] h-full"
                >
                    <FrankieOneKYCSmartUI />
                </DialogContent>
            </div>
        </Dialog>
    )
}
