import { create } from 'zustand'

interface State {
    securityCodes: string[] | null
    setSecurityCodes: (securityCodes: string[] | null) => void
}

const useTradeModalStore = create<State>((set) => ({
    securityCodes: null,
    setSecurityCodes: (securityCodes: string[] | null) => set({ securityCodes }),
}))

export default useTradeModalStore
