import { cn } from '@/common/utils/utils'

type Props = {
    children: React.ReactNode
    className?: string
}

export function H1({ children, className }: Props) {
    return <h1 className={cn('text-4xl mb-8 md:text-40 font-semibold', className)}>{children}</h1>
}
