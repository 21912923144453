import { AlertCircle } from 'lucide-react'
import { Alert, AlertDescription } from '@/common/ui/alert'
import { Button } from '@/common/ui/button'
import { MouseEventHandler } from 'react'
import { cn } from '@/common/utils/utils'
import { H3 } from '@/common/ui'

type Props = {
    onClick?: MouseEventHandler
    className?: string
    heading: string
    text: string
    buttonText?: string
}

export function NoKycAlert({ heading, text, buttonText, onClick, className }: Props) {
    return (
        <Alert
            variant="destructive"
            className={cn(
                'bg-[#FEE2E2] border-none rounded-tl-3xl rounded-br-3xl rounded-tr-none rounded-bl-none p-5 xs:p-10',
                className,
            )}
        >
            <div className="grid grid-cols-[50px_1fr] xs:grid-cols-[60px_1fr]">
                <AlertCircle className="h-[36px] w-[36px]" strokeWidth={1} />
                <div>
                    <H3 className="text-[#33404F] mb-4 text-xl xs:text-xl">{heading}</H3>
                    <AlertDescription>
                        <p className="text-[#33404F]">{text}</p>
                        {onClick && buttonText && (
                            <Button variant="outline" className="mt-8 text-black" onClick={onClick}>
                                {buttonText}
                            </Button>
                        )}
                    </AlertDescription>
                </div>
            </div>
        </Alert>
    )
}
