import { useFetchTradingAccount } from '@/trading/tradingQueries'
import { cn } from '@/common/utils/utils'
import { CircleAlert } from 'lucide-react'
import { FC } from 'react'

type Props = {
    className?: string
}

export const NoTradingAccountAlert: FC<Props> = ({ className }) => {
    const { data: tradingAccount } = useFetchTradingAccount()
    if (tradingAccount?.status === 'APPROVED') return null
    return (
        <p className={cn('text-destructive mb-6 flex', className)}>
            <CircleAlert />
            <span className="ml-2">Your trading account has not been created yet. Please contact support.</span>
        </p>
    )
}
