import { Helmet } from 'react-helmet'
import { APP } from '@/common/strings'
import { FC } from 'react'
import { H1 } from '@/common/ui/h1'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { Holdings } from './holdings'
import { Orders } from './orders'
import Money from '@/common/models/money'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'
import { ValuesGrid } from '@/common/ui/ValuesGrid'
import { ValuesGridItem } from '@/common/ui/ValuesGridItem'
import { Tabs, TabsContent, TabsTrigger } from '@/common/ui/tabs'
import { TabsList } from '@radix-ui/react-tabs'
import { useListHoldings, useListOrders } from '@/trading/tradingQueries'
import useKycStore from '@/kyc/kycStore'
import { seconds } from '@/common/utils/utils'

export function Portfolio() {
    const { showPortfolioPage } = useFeatureFlags()
    const { kycComplete } = useKycStore()
    const { data: holdings, isFetching: fetchingHoldings } = useListHoldings({
        enabled: kycComplete,
        refetchInterval: seconds(20),
    })
    const { data: orders, isFetching: fetchingOrders } = useListOrders({
        enabled: kycComplete,
        refetchInterval: seconds(20),
    })

    if (!showPortfolioPage) return null

    if (fetchingHoldings || fetchingOrders) return <FullScreenLoader className="h-64" />
    type TabProps = {
        label: string
        value: string
    }
    const Tab: FC<TabProps> = ({ label, value }) => {
        return (
            <TabsTrigger
                value={value}
                className="data-[state=active]:font-semibold border-none text-foreground w-full py-3 rounded-b-none data-[state=active]:border-solid data-[state=active]:border-b-primary-light data-[state=active]:border-2 data-[state=active]:border-t-transparent data-[state=active]:border-l-transparent data-[state=active]:border-r-transparent"
            >
                {label}
            </TabsTrigger>
        )
    }
    return (
        <>
            <Helmet>
                <title>Portfolio | {APP.title}</title>
            </Helmet>
            <H1>Portfolio</H1>
            <ValuesGrid>
                <ValuesGridItem label="Portfolio Value" value={`${Money.of(holdings?.totalValue ?? 0)}`} />
                <ValuesGridItem label="Total Invested" value={`${Money.of(0)}`} />
                <ValuesGridItem label="Profit / Loss" value={`${Money.of(0)}`} />
            </ValuesGrid>
            <Tabs defaultValue="positions" className="mt-4">
                <TabsList className="p-0 h-auto bg-background flex justify-center w-[200px] mt-12 mb-8">
                    <Tab label="Positions" value="positions" />
                    <Tab label="Orders" value="orders" />
                </TabsList>
                <TabsContent value="positions">
                    <Holdings holdings={holdings?.holdings ?? []} />
                </TabsContent>
                <TabsContent value="orders">
                    <Orders orders={orders?.orders ?? []} />
                </TabsContent>
            </Tabs>
        </>
    )
}
