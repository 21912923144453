import * as yup from 'yup'
import { useForm } from 'react-hook-form'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/common/ui/tooltip'
import { yupResolver } from '@hookform/resolvers/yup'
import { InstrumentT } from '@/common/types'
import { Button } from '@/common/ui/button'
import { LabelInput } from '@/common/ui/LabelInput'
import { H1, H2, Label } from '@/common/ui'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem } from '@/common/ui/select'
import { CircleMinus, Info, TimerReset } from 'lucide-react'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import useTradeStore from '@/trading/tradeStore'
import { tradeEstimatedBrokerage } from '@/common/utils/utils'
import { ORDER_EXPIRY, ORDER_TYPE } from '@/common/constants'
import { FEATURES } from '@/common/config'
import { TRADING } from '@/common/strings'
import Money from '@/common/models/money'
import { CompanyHead } from '@/equities/companyHead'
import { useFetchBalance } from '@/wallet/walletQueries'

const schema = yup
    .object({
        price: yup
            .number()
            .default(0)
            .typeError('Please provide price per unit')
            .required('Please provide price per unit'),
        shares: yup
            .number()
            .min(1, 'Choose min of 1 share')
            .typeError('Please provide number of shares')
            .required('Please provide number of shares'),
    })
    .required()

type Props = {
    instrument: InstrumentT
    onSubmit: () => void
}
export const TradeForm: FC<Props> = ({ instrument, onSubmit }) => {
    const [sizeAboveMin, setSizeAboveMin] = useState(true)
    const [availableBalanceSufficient, setAvailableBalanceSufficient] = useState(false)
    const { data: balance } = useFetchBalance()

    const {
        orderType,
        setOrderType,
        limitPrice,
        setLimitPrice,
        orderExpiry,
        setOrderExpiry,
        numberOfShares,
        setNumberOfShares,
        setInstrumentPrice,
    } = useTradeStore()

    const formRef = useRef<HTMLFormElement>(null!)

    const prices = instrument.priceInfo
    const price = prices.lastPrice || prices.closingPrice
    const sharesAmount = Money.of(orderType === 'LIMIT' ? limitPrice : price * 1.05).multiply(numberOfShares || 0)
    const fee = tradeEstimatedBrokerage(sharesAmount)
    const amount = sharesAmount.add(fee)

    useEffect(() => {
        setLimitPrice(prices.sellPrice)
    }, [prices.sellPrice, setLimitPrice])

    useEffect(() => {
        if (balance?.availableBalance && balance?.availableBalance >= amount.value) {
            setAvailableBalanceSufficient(true)
        }
    }, [balance, setAvailableBalanceSufficient, amount.value])

    useEffect(() => {
        setInstrumentPrice(price)
    }, [price, setInstrumentPrice])

    const getMinShares = (price: number) => Math.ceil(500 / price)
    const resetToMin = useCallback(
        (price: number) => {
            const min = getMinShares(price)
            setNumberOfShares(min)
        },
        [setNumberOfShares],
    )

    const initalSharesSet = useRef(false)
    useEffect(() => {
        if (!initalSharesSet.current) {
            resetToMin(price)
            initalSharesSet.current = true
        } else {
            if (numberOfShares < getMinShares(price)) {
                setSizeAboveMin(false)
            } else {
                setSizeAboveMin(true)
            }
        }
    }, [price, numberOfShares, setNumberOfShares, resetToMin])

    const {
        register,
        handleSubmit,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })

    const onFormSubmit = () => {
        onSubmit()
    }

    const clearForm = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setOrderType('MARKET_TO_LIMIT')
        setValue('price', prices.sellPrice)
        const min = Math.ceil(500 / price)
        setNumberOfShares(min)
        clearErrors()
        setLimitPrice(prices.sellPrice)
        formRef.current.reset()
    }

    const onSharesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNumberOfShares(parseInt(e.target.value))
    }

    return (
        <div>
            <H2 className="text-center">Place Buy Order</H2>
            <CompanyHead className="mb-2" instrument={instrument} />
            <div className="flex items-center justify-between mb-3">
                <H1 className="mb-0 mt-2">{`${Money.of(price)}`}</H1>
            </div>
            <form ref={formRef} className="flex flex-col gap-4" onSubmit={handleSubmit(onFormSubmit)}>
                <p className="flex gap-2 text-xs lg:text-sm">
                    <TimerReset size={18} className="-mt-0.5 shrink-0" />
                    <span className="lg:-mt-0.5">20 minute delayed pricing</span>
                </p>
                <p className="flex gap-2 items-center">
                    <strong>Order type:</strong>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Info size={16} strokeWidth={2} />
                            </TooltipTrigger>
                            <TooltipContent className="ml-20 xs:ml-10" arrowClassName="-mt-1 -ml-20 xs:ml-10">
                                <p className="w-48 sm:w-64">{TRADING.marketOrderNote}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <span>Market</span>
                </p>
                {FEATURES.limitOrder && (
                    <div>
                        <Label htmlFor="order-type">Order Type</Label>
                        <Select value={orderType} defaultValue="limit" onValueChange={setOrderType}>
                            <SelectTrigger
                                id="order-type"
                                className="w-full rounded-full border border-gray-300 py-2 px-4 !mt-0 focus:ring-transparent"
                            >
                                <SelectValue placeholder="Select salutation" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value="LIMIT">{ORDER_TYPE.LIMIT}</SelectItem>
                                    <SelectItem value="MARKET_TO_LIMIT">{ORDER_TYPE.MARKET_TO_LIMIT}</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                )}
                {orderType === 'LIMIT' && (
                    <div className="flex flex-col sm:flex-row items-start sm:items-end space-x-4 gap-4">
                        <div className="sm:w-1/2">
                            <LabelInput
                                type="number"
                                {...register('price', {
                                    valueAsNumber: true,
                                })}
                                className="w-full"
                                error={errors.price?.message}
                                label="Price ($) per unit"
                                value={limitPrice}
                                onChange={(e) => setLimitPrice(parseInt(e.target.value) || 0)}
                                placeholder="0.00"
                                id="price"
                            />
                        </div>
                        <div className="sm:w-1/2 flex space-x-4 mb-1.5">
                            <span className="flex items-center gap-2 w-max grow">
                                <strong>B</strong>{' '}
                                <span className="bg-secondary-light py-1 px-4 w-full max-w-28">
                                    {`${Money.of(prices.buyPrice)}`}
                                </span>
                            </span>
                            <span className="flex items-center gap-2 w-max grow">
                                <strong>S</strong>{' '}
                                <span className="bg-destructive-light py-1 px-4 w-full max-w-28">
                                    {`${Money.of(prices.sellPrice)}`}
                                </span>
                            </span>
                        </div>
                    </div>
                )}
                <div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
                    <LabelInput
                        className=""
                        type="number"
                        {...register('shares', {
                            valueAsNumber: true,
                        })}
                        value={numberOfShares}
                        onChange={onSharesChange}
                        error={errors.shares?.message}
                        label="Shares (#)"
                        placeholder="0"
                        id="number-of-shares"
                    />
                    <div className="sm:w-1/2 shrink-0">
                        <Label htmlFor="order-expiry">Duration</Label>
                        <Select value={orderExpiry} onValueChange={setOrderExpiry}>
                            <SelectTrigger
                                id="order-expiry"
                                className="w-full rounded-full border border-gray-300 py-2 px-4 !mt-0 focus:ring-transparent"
                            >
                                <SelectValue placeholder="Select salutation" />
                            </SelectTrigger>
                            <SelectContent className="w-full">
                                <SelectGroup>
                                    <SelectItem value="VALID_TILL_EOD">{ORDER_EXPIRY['VALID_TILL_EOD']}</SelectItem>
                                    {/* <SelectItem value="VALID_TILL_CANCEL">
                                        {ORDER_EXPIRY['VALID_TILL_CANCEL']}
                                    </SelectItem> */}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                {!sizeAboveMin && (
                    <p className="text-sm text-red-700">
                        Below minimum trade size.{' '}
                        <a className="text-inherit underline cursor-pointer" onClick={() => resetToMin(price)}>
                            Reset to minimum
                        </a>
                    </p>
                )}
                <div className="space-y-2 mt-2">
                    <p className="flex items-center gap-2">
                        <strong>Estimated brokerage:</strong>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Info size={16} strokeWidth={2} />
                                </TooltipTrigger>
                                <TooltipContent className="mr-4 xs:mr-0" arrowClassName="-mt-1 -mr-4 xs:mr-0">
                                    <p className="w-60">{TRADING.brokerageNote}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <span>{`${fee}`}</span>
                    </p>
                    <p className="flex items-center gap-2">
                        <strong>Estimated Amount (incl. fees):</strong>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Info size={16} strokeWidth={2} />
                                </TooltipTrigger>
                                <TooltipContent className="ml-20 xs:ml-10" arrowClassName="-mt-1 -ml-20 xs:ml-10">
                                    <p className="w-60">
                                        Estimated amount is offer price plus 5% for Market Orders, and estimated
                                        brokerage.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <span>{`${amount}`}</span>
                    </p>

                    <p className="flex items-center gap-2">
                        <strong>Available funds:</strong>
                        <span
                            className={!availableBalanceSufficient ? 'text-red-700' : ''}
                        >{`${Money.of(balance?.availableBalance)}`}</span>
                    </p>
                </div>
                <div className="flex justify-between mt-4">
                    <Button
                        disabled={!availableBalanceSufficient || !sizeAboveMin}
                        type="submit"
                        variant="secondary"
                        size="lg"
                    >
                        Review Trade
                    </Button>
                    <Button variant="link" onClick={clearForm}>
                        Clear form <CircleMinus size={16} className="ml-2" />
                    </Button>
                </div>
            </form>
        </div>
    )
}
