import { Helmet } from 'react-helmet'

import { APP } from '@/common/strings'
import AccountSettings from './accountSettings'
import { useFetchPayId } from '@/wallet/walletQueries'
import { H1 } from '@/common/ui'

export function Profile() {
    useFetchPayId()

    return (
        <>
            <Helmet>
                <title>Profile | {APP.title}</title>
            </Helmet>
            <H1>Profile</H1>
            <AccountSettings />
        </>
    )
}
