import { User } from '@/common/types'
import { create } from 'zustand'

interface State {
    user: User | null
}

interface UserState extends State {
    setUser: (user: User | null) => void
}

const initialState: State = {
    user: null,
}

const useUserStore = create<UserState>((set) => ({
    ...initialState,
    setUser: (user) => set(() => ({ user })),
}))

export default useUserStore
