import React, { useState } from 'react'
import { cn } from '@/common/utils/utils'
import { InputError } from './InputError'
import { Label } from '@/common/ui/Label'
import { Eye, EyeOff } from 'lucide-react'

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: string
}

export const PasswordInput = React.forwardRef<HTMLInputElement, Props>(
    ({ className, required, id, label, error, ...props }: Props, ref) => {
        const [showPassword, setShowPassword] = useState(false)

        return (
            <div className="flex flex-col w-full">
                {label && <Label htmlFor={id}>{label}</Label>}
                <div className="relative flex justify-end items-end">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        ref={ref}
                        id={id}
                        required={required}
                        className={cn('w-full rounded-full border border-gray-300 py-2 px-4', className)}
                        {...props}
                    />
                    <div
                        onClick={() => setShowPassword((prev) => !prev)}
                        className="absolute h-[34px] w-[34px] m-1 rounded-full bg-accent grid place-items-center"
                    >
                        {showPassword ? <EyeOff strokeWidth={1} /> : <Eye strokeWidth={1} />}
                    </div>
                </div>

                <InputError message={error} />
            </div>
        )
    },
)
