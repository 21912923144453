import { Button } from '@/common/ui/button'
import { DataTable } from '@/common/ui/data-table'
import { HoldingT } from '@/common/types'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { CompanyHead } from '@/equities/companyHead'
import Money from '@/common/models/money'
import useTradeModalStore from '@/trading/tradeModalStore'

type holdingsProps = {
    holdings: HoldingT[]
}

export function Holdings(props: holdingsProps) {
    const { holdings } = props
    const { setSecurityCodes } = useTradeModalStore()

    const columns: ColumnDef<HoldingT>[] = useMemo(
        () => [
            {
                accessorKey: 'securityCode',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Company</span>
                },
                cell: ({ row }) => {
                    const instrumentCode = row.original.securityCode
                    return (
                        <CompanyHead
                            nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                            instrument={{
                                securityCode: instrumentCode,
                                companyName: row.original.name,
                            }}
                        />
                    )
                },
            },
            {
                accessorKey: 'units',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Shares Held</span>
                },
            },
            {
                accessorKey: 'lastPrice',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Last Price</span>
                },
                cell: ({ row }) => {
                    const lastPrice = Money.of(row.original.lastPrice)
                    return <>{`${lastPrice}`}</>
                },
            },
            {
                accessorKey: 'currentValue',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Value</span>
                },
                cell: ({ row }) => {
                    const lastPrice = Money.of(row.original.lastPrice)
                    const sharesHeld = row.original.units
                    return <>{`${lastPrice.multiply(sharesHeld)}`}</>
                },
            },
            {
                accessorKey: 'trade',
                header: () => {
                    return <span className="font-bold text-black">Trade</span>
                },
                cell: ({ row }) => {
                    return (
                        <Button
                            className="px-5"
                            variant="tertiary"
                            size="sm"
                            onClick={() => setSecurityCodes([row.original.securityCode])}
                        >
                            Place Order
                        </Button>
                    )
                },
                enableSorting: false,
            },
        ],
        [setSecurityCodes],
    )
    return <DataTable columns={columns} data={holdings.length ? holdings : []} noDataMessage="No shares held yet..." />
}
