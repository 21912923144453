import { cn } from '@/common/utils/utils'
import { Spinner } from './spinner'

type Props = {
    dark?: boolean
    className?: string
}

export const FullScreenLoader = ({ className, dark = false }: Props) => {
    return (
        <div
            className={cn(
                'flex items-center justify-center h-[100vh] bg-primary',
                `${dark ? 'bg-primary' : 'bg-white'}`,
                className,
            )}
        >
            <Spinner size="lg" />
        </div>
    )
}
