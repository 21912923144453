import { FC, useEffect, useState } from 'react'

import { InstrumentT } from '@/common/types'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import { TradeForm } from './tradeForm'
import { TradeReview } from './tradeReview'
import useTradeStore from '@/trading/tradeStore'
import { OrderConfirmation } from './orderConfirmation'
import { TradeSelect } from './tradeSelect'
import { Spinner } from '../common/ui/spinner'
import { cn } from '@/common/utils/utils'
import { Button } from '../common/ui/button'
import { CircleAlertIcon } from 'lucide-react'
import KycModal from '@/kyc/kycModal'
import { H3 } from '@/common/ui'
import { useFetchKYC } from '@/kyc/kycQueries'

type Props = {
    instruments: InstrumentT[] | null
    loading?: boolean
    onClose: () => void
    pledges?: { [key: string]: number } | null
}
export type TradeState = 'select' | 'order' | 'review' | 'success' | null

export const TradeModal: FC<Props> = ({ instruments, onClose, pledges, loading = false }) => {
    const [tradeStatus, setTradeStatus] = useState<TradeState>(null)
    const [instrument, setInstrument] = useState<InstrumentT | null>(null)
    const setRequestId = useTradeStore((state) => state.setRequestId)
    const resetState = useTradeStore((state) => state.resetState)
    const [openKyc, setOpenKyc] = useState<boolean>(false)
    const closeKYCModal = () => setOpenKyc(false)

    const { data: kyc } = useFetchKYC({ enabled: true })
    const isKYCPending = kyc?.status && kyc?.status !== 'VERIFICATION_SUCCESS'

    const onSelectionMade = (instrument: InstrumentT) => {
        setInstrument(instrument)
        setTradeStatus('order')
    }

    const onBackToOrder = () => {
        setTradeStatus('order')
    }

    const onOrderReview = () => {
        setTradeStatus('review')
    }

    const onOrderSubmit = () => {
        setTradeStatus('success')
    }

    const onModalClose = () => {
        setTradeStatus(null)
        resetState()
        onClose()
    }

    useEffect(() => {
        setRequestId()
    }, [setRequestId])

    useEffect(() => {
        if (!instruments) return
        if (instruments.length === 1) {
            setInstrument(instruments[0])
            setTradeStatus('order')
        } else {
            setTradeStatus('select')
        }
    }, [instruments])

    const tradeUI = (tradeStatus: TradeState) => {
        if (tradeStatus === 'select' && instruments) {
            return <TradeSelect instruments={instruments} onSelectionMade={onSelectionMade} pledges={pledges} />
        }

        if (!instrument) return null
        switch (tradeStatus) {
            case 'review':
                return (
                    <TradeReview
                        instrument={instrument}
                        onBackToOrder={onBackToOrder}
                        onSubmit={onOrderSubmit}
                        pledges={pledges}
                    />
                )
            case 'success':
                return <OrderConfirmation instrument={instrument} onClose={onModalClose} />
            case 'order':
                return <TradeForm instrument={instrument} onSubmit={onOrderReview} />
            default:
                return null
        }
    }

    const noKycWarningUI = () => {
        return (
            <div>
                <div className="flex items-center mb-4">
                    <CircleAlertIcon className="inline text-destructive px-1" size={40} />
                    <H3 className="m-0 pt-1 pl-2 text-xl">Please verify your identity</H3>
                </div>
                <p className="py-1">
                    You have not verified your identity as of yet. In order to trade on the SIX platform, you must
                    verify your identity.
                </p>
                <p className="font-bold py-2">Here's is what you'll need to trade:</p>
                <ul className="list-disc pl-5 text-sm xs:text-base">
                    <li>5 minutes of your time to complete the application</li>
                    <li>Some ID ( this could be a driver's license, passport or medicate card )</li>
                    <li>You must be over 18 years of age</li>
                    <li>You must be an Australian citizen, permanent resident or have an Australian Visa.</li>
                </ul>
                <Button className="mt-8" variant="secondary" fullWidth onClick={() => setOpenKyc(true)}>
                    Verify Identity
                </Button>
            </div>
        )
    }

    const loader = (
        <div className={cn('flex items-center justify-center')}>
            <Spinner size="lg" />
        </div>
    )

    return (
        <>
            <Dialog open={loading || (!!instruments && instruments.length > 0)}>
                <DialogContent
                    onClose={onModalClose}
                    className="px-5 py-12 max-h-[90vh] min-h-[50vh] w-[94vw] max-w-[680px] overflow-y-auto xs:p-20"
                >
                    {loading
                        ? loader
                        : isKYCPending && !openKyc
                          ? noKycWarningUI()
                          : tradeStatus && tradeUI(tradeStatus)}
                </DialogContent>
            </Dialog>
            <KycModal open={openKyc} onClose={closeKYCModal} />
        </>
    )
}
