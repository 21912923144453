import { Controller } from 'react-hook-form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/common/ui/select'
import { Label } from '@/common/ui'
import { InputError } from '@/common/ui/InputError'

type Props = {
    label: string
    fieldName: string
    control: any
    setValue: any
    trigger: any
    error: string
    defaultValue?: string
    items: any[]
    className?: string
}

export function SelectField({
    label,
    fieldName,
    control,
    setValue,
    trigger,
    error,
    defaultValue,
    items,
    className,
}: Props) {
    return (
        <div>
            <Label htmlFor={fieldName} className={className}>
                {label}
            </Label>
            <Controller
                name={fieldName}
                control={control}
                render={({ field }) => {
                    const onChange = (value: string) => {
                        field.onChange()
                        setValue(fieldName, value)
                        trigger(fieldName)
                    }
                    return (
                        <Select defaultValue={defaultValue} onValueChange={onChange}>
                            <SelectTrigger
                                id={fieldName}
                                className="w-full rounded-full border border-gray-300 py-2 px-4 mt-1 focus:ring-transparent"
                            >
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent {...field}>
                                <SelectGroup>
                                    {items.map((item: any) => (
                                        <SelectItem key={item} value={item}>
                                            {item}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    )
                }}
            />
            <InputError message={error} />
        </div>
    )
}
