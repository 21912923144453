import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FullScreenLoader } from '../common/ui/FullScreenLoader'
import kycIllustration from '@/common/assets/kyc-illustration.webp'
import { H1 } from '@/common/ui/h1'
import { Button } from '@/common/ui/button'
import { API_ROUTES, APP_ROUTES } from '@/common/constants'
import { useQueryClient } from '@tanstack/react-query'
import { FRANKIEONE_API } from '@/common/config'
import { useInitKYC } from './kycQueries'

const FRAKIEONE_SCRIPT_ID = 'frankie-one-script'

const widgetConfiguration = {
    frankieBackendUrl: FRANKIEONE_API.kycUi,
    successScreen: {
        ctaUrl: `${APP_ROUTES.PROFILE}`,
    },
    failureScreen: {
        ctaUrl: `${APP_ROUTES.PROFILE}`,
    },
    documentTypes: ['NATIONAL_HEALTH_ID', { type: 'PASSPORT', acceptedCountries: 'ALL' }, 'DRIVERS_LICENCE'],
    idScanVerification: false,
    checkProfile: 'auto',
    maxAttemptCount: 5,
    googleAPIKey: false,
    phrases: {
        document_select: {
            title: 'Choose your ID',
            hint_message: "Choose which ID you'd like to provide.",
        },
    },
    requestAddress: { acceptedCountries: ['AUS', 'NZL'] },
    consentText: "I agree with the terms described in the Consent section of the Company's webpage",
    ageRange: [18, 125],
    organisationName: 'Sustainable Investment Exchange (SIX)',
}

export const FrankieOneKYCSmartUI = React.memo(() => {
    const { data: kyc, isRefetching, isLoading } = useInitKYC()
    const queryClient = useQueryClient()
    const [isKYCStart, setIsKYCStart] = useState(false)
    const smartUIRef = useRef()

    const loadFrankieUI = useCallback(() => {
        if (!kyc) return
        /* @ts-ignore */
        window.frankieFinancial.initialiseOnboardingWidget({
            ffToken: kyc.token,
            entityId: kyc.entityId,
            config: widgetConfiguration,
            width: '100%',
            height: '100%',
        })
    }, [kyc])

    useEffect(() => {
        if (!isKYCStart) return
        const existingScript = document.getElementById(FRAKIEONE_SCRIPT_ID)
        if (existingScript) {
            loadFrankieUI()
            return
        }
        const script = document.createElement('script')
        script.src = FRANKIEONE_API.script
        script.id = FRAKIEONE_SCRIPT_ID
        document.body.appendChild(script)
        script.onload = () => {
            loadFrankieUI()
        }
    }, [loadFrankieUI, isKYCStart])

    useEffect(() => {
        return () => {
            const existingScript = document.getElementById(FRAKIEONE_SCRIPT_ID)
            existingScript?.remove()
            // @ts-ignore
            delete window.frankieFinancial
            queryClient.removeQueries({ queryKey: [API_ROUTES.kycInit] })
        }
    }, [queryClient])

    if (!kyc || isRefetching || isLoading) {
        return <FullScreenLoader />
    }

    if (!isKYCStart) {
        return (
            <div className="w-full h-full overflow-y-auto relative">
                <img src={kycIllustration} className="w-full h-24 md:h-[220px] object-cover sticky top-0 z-10" />
                <div className="px-8 py-5">
                    <H1 className="text-xl lg:text-inherit mb-3 mt-2">Verify your identity</H1>
                    <p className="text-xs lg:text-sm">
                        Your personal information will be checked against third party records, including a credit
                        reporting agency and the Australian Government's Document Verification Service (DVS), to assess
                        whether your personal information matches those records and to assist in verifying your identity
                        for the purposes of the Anti-Money Laundering and Counter-Terrorism Act 2006. If you disagree
                        with having your personal information shared with third parties or your identity verified we may
                        not be able to open an account for you. By continuing, you agree that you are authorised to
                        provide us these details and you understand that it will be checked against third party records
                        for this purpose.
                    </p>
                    <Button variant="secondary" className="mt-4" onClick={() => setIsKYCStart(true)}>
                        Continue
                    </Button>
                    <footer className="mt-3 text-sm text-muted-foreground text-center">Powered by FrankieOne</footer>
                </div>
            </div>
        )
    }

    return (
        <div id="smart-ui" className="h-full w-full max-h-screen">
            {/* @ts-ignore */}
            <ff-onboarding-widget ref={smartUIRef} />
        </div>
    )
})
