import { create } from 'zustand'

interface State {
    isForgotPassword: boolean
    username: string
}
interface AuthState extends State {
    setIsForgotPassword: (isForgotPassword: boolean) => void
    setUsername: (username: string) => void
}

const initialState: State = {
    isForgotPassword: false,
    username: '',
}

const useAuthStore = create<AuthState>((set) => ({
    ...initialState,
    setIsForgotPassword: (isForgotPassword) => set(() => ({ isForgotPassword })),
    setUsername: (username) => set(() => ({ username })),
}))

export default useAuthStore
