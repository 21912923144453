import { InstrumentT } from '@/common/types'
import { useEffect, useState } from 'react'
import { TradeModal } from './tradeModal'
import useTradeModalStore from './tradeModalStore'
import { getCompanyBySecurityCode } from './tradingApi'
import { toast } from 'sonner'
import useCampaignStore from './campaignStore'
import { getPledgeBySecurityCode } from '@/campaigns/campaignsApi'

export const TradeModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedTradeInstruments, setSelectedTradeInstruments] = useState<InstrumentT[] | null>(null)
    const [isFetchingInstruments, setIsFetchingInstruments] = useState<boolean>(false)
    const [isFetchingPledges, setIsFetchingPledges] = useState<boolean>(false)
    const [pledges, setPledges] = useState<{ [key: string]: number } | null>(null)

    const { securityCodes, setSecurityCodes } = useTradeModalStore()
    const { currentCampaign, setCurrentCampaign } = useCampaignStore()

    const handleOrder = async (securityCodes: string[]) => {
        setIsFetchingInstruments(true)
        try {
            const instruments = await Promise.all(securityCodes.map((code) => getCompanyBySecurityCode(code)))
            setSelectedTradeInstruments(instruments)
            fetchPledges(securityCodes)
        } catch (error) {
            toast.error('Sorry, there was a problem starting your order')
        }
        setIsFetchingInstruments(false)
    }

    const fetchPledges = async (securityCodes: string[]) => {
        setIsFetchingPledges(true)
        if (!currentCampaign) return
        const pledges: { [key: string]: number } = {}
        await Promise.all(
            securityCodes.map(async (code) => {
                pledges[`"${code}"`] = await getPledgeBySecurityCode(currentCampaign.campaignId, code)
            }),
        )
        setPledges(pledges)
        setIsFetchingPledges(false)
    }

    useEffect(() => {
        if (securityCodes) {
            handleOrder(securityCodes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [securityCodes])

    const onTradeModalClose = () => {
        setSelectedTradeInstruments(null)
        setSecurityCodes(null)
        setCurrentCampaign(null)
    }

    return (
        <>
            {children}
            <TradeModal
                onClose={onTradeModalClose}
                loading={isFetchingInstruments || isFetchingPledges}
                instruments={selectedTradeInstruments}
                pledges={pledges}
            />
        </>
    )
}
