import { OrderExpiry, OrderAction, OrderType } from '@/common/types'
import { create } from 'zustand'

interface State {
    orderType: OrderType
    orderAction: OrderAction
    limitPrice: number
    orderExpiry: OrderExpiry
    numberOfShares: number
    instrumentPrice: number
    requestId: string | null
}
interface TradeState extends State {
    setOrderAction: (action: OrderAction) => void
    setOrderType: (age: OrderType) => void
    setLimitPrice: (price: number) => void
    setOrderExpiry: (expiry: OrderExpiry) => void
    setNumberOfShares: (numberOfShares: number) => void
    setInstrumentPrice: (price: number) => void
    setRequestId: () => void
    resetState: () => void
}

const initialState: State = {
    orderType: 'MARKET_TO_LIMIT',
    orderAction: 'BUY',
    limitPrice: 1,
    instrumentPrice: 1,
    orderExpiry: 'VALID_TILL_EOD',
    numberOfShares: 1,
    requestId: null,
}

const useTradeStore = create<TradeState>((set) => ({
    ...initialState,
    setOrderAction: (action: OrderAction) => set(() => ({ orderAction: action })),
    setOrderType: (type: OrderType) => set(() => ({ orderType: type })),
    setLimitPrice: (price: number) => set(() => ({ limitPrice: price })),
    setOrderExpiry: (expirty: OrderExpiry) => set(() => ({ orderExpiry: expirty })),
    setNumberOfShares: (numberOfShares: number) => set(() => ({ numberOfShares })),
    setInstrumentPrice: (price: number) => set(() => ({ instrumentPrice: price })),
    setRequestId: () => set(() => ({ requestId: crypto.randomUUID() })),
    resetState: () =>
        set(() => ({
            ...initialState,
            requestId: crypto.randomUUID(),
        })),
}))

export default useTradeStore
