import { Button, H2 } from '@/common/ui'
import { DialogDescription, DialogHeader, DialogTitle } from '@/common/ui/dialog'
import { Table, TableBody, TableCell, TableRow } from '@/common/ui/table'
import { Check } from 'lucide-react'
import { AccountDetails } from './types'
import Money from '@/common/models/money'

type Props = {
    accountDetails: AccountDetails
    amount: number
    onClose: () => void
}

export default function WithdrawAckDialog(props: Props) {
    const { accountDetails, amount } = props
    const currentTime = new Date()
    const timeString = currentTime.toLocaleTimeString('en-AU', { timeStyle: 'short', hour12: true })
    return (
        <DialogHeader className="text-center">
            <DialogTitle className="text-center text-3xl">
                <div className="p-2">
                    <Check className="rounded-full bg-secondary self-center m-auto" size={50} />
                    <div className="py-2">Your withdrawal has been successful</div>
                </div>
            </DialogTitle>
            <DialogDescription>
                <p className="text-center">
                    {currentTime.toLocaleDateString()}, {timeString}
                </p>
                <p className="text-center">
                    Please allow 24-48 hours for funds to appear in your nominated bank account
                </p>
            </DialogDescription>
            <H2 className="text-center py-5">Withdrawal Summary</H2>
            <Table className="w-[80%] m-auto">
                <TableBody>
                    <TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell className="font-bold">{`${Money.of(amount)}`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Account Name</TableCell>
                        <TableCell className="font-bold">{accountDetails.bankAccountName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>BSB</TableCell>
                        <TableCell className="font-bold">{accountDetails.bsb}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Account number</TableCell>
                        <TableCell className="font-bold">{accountDetails.bankAccountNumber}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className="pt-6 w-[80%] m-auto flex justify-between">
                <Button variant={'secondary'} type="button" onClick={props.onClose}>
                    Go to wallet
                </Button>
                <Button type="button" onClick={props.onClose} variant={'ghost'}>
                    Close
                </Button>
            </div>
        </DialogHeader>
    )
}
