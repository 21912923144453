import React from 'react'
import { cn } from '@/common/utils/utils'
import { InputError } from './InputError'
import { Label } from '@/common/ui/Label'

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: string
}

export const LabelInput = React.forwardRef<HTMLInputElement, Props>(
    ({ className, required, id, label, error, ...props }: Props, ref) => {
        return (
            <div className="flex flex-col w-full">
                {label && (
                    <Label className="text-sm" htmlFor={id}>
                        {label}
                    </Label>
                )}
                <input
                    ref={ref}
                    id={id}
                    required={required}
                    className={cn('w-full rounded-full border border-gray-300 py-2 px-4', className)}
                    {...props}
                />
                <InputError message={error} />
            </div>
        )
    },
)
