import { KYCStatus } from '@/common/types'
import { create } from 'zustand'

interface State {
    kyc: KYCStatus | null
    setKyc: (status: KYCStatus) => void
    kycComplete: boolean
    setKycComplete: (complete: boolean) => void
}

const useKycStore = create<State>((set) => ({
    kyc: null,
    setKyc: (status: KYCStatus) => set({ kyc: status }),
    kycComplete: false,
    setKycComplete: (complete: boolean) => set({ kycComplete: complete }),
}))

export default useKycStore
