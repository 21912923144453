import { FC, ReactNode } from 'react'

import { EquityT, Maybe } from '@/common/types'
import { H3 } from '@/common/ui/h3'
import { inMillionsOrBillions } from '@/common/utils/utils'

type KeyStatisticItemProps = {
    lable: string
    value: ReactNode
}
const EquityInsightItem: FC<KeyStatisticItemProps> = ({ lable, value }) => {
    return (
        <li className="flex space-x-3 items-end last:border-none justify-between px-2 py-3 border-b border-gray-300">
            <span className="text-sm">{lable}</span>
            <strong className="text-sm font-semibold">{value}</strong>
        </li>
    )
}

type Props = {
    statistics: Maybe<EquityT['statistics']>
    about: Maybe<EquityT['about']>
    dayRange: Maybe<[number, number]>
    yearRange: Maybe<[number, number]>
}
export const EquityInsights: FC<Props> = ({ statistics, about, dayRange, yearRange }) => {
    if (!statistics || !about) return

    return (
        <aside className="min-w-[12rem] flex gap-3 flex-col sm:flex-row xl:flex-col xl:w-1/4">
            <div className="w-full">
                <H3 className="mb-4">Key Statistics</H3>
                <ul className="w-full px-4 py-4 rounded-xl bg-gray-100">
                    {statistics.dayOpenedAt && <EquityInsightItem lable="Open" value={statistics.dayOpenedAt} />}
                    {dayRange && <EquityInsightItem lable="Day Range" value={`$${dayRange[0]} - $${dayRange[1]}`} />}
                    {yearRange && (
                        <EquityInsightItem lable="Year Range" value={`$${yearRange[0]} - $${yearRange[1]}`} />
                    )}
                    {statistics.marketCapitalisation && (
                        <EquityInsightItem
                            lable="Market Cap"
                            value={inMillionsOrBillions(statistics.marketCapitalisation)}
                        />
                    )}
                    {statistics.volume && (
                        <EquityInsightItem lable="Volume" value={inMillionsOrBillions(statistics.volume)} />
                    )}
                    {statistics.peRatio && <EquityInsightItem lable="PE ratio" value={statistics.peRatio} />}
                    {statistics.dividend && <EquityInsightItem lable="Dividend" value={statistics.dividend} />}
                </ul>
            </div>

            <div className="w-full">
                <H3 className="mb-4 xl:mt-8">About</H3>
                <ul className="w-full px-4 py-4 rounded-xl bg-gray-100">
                    {about.ceo && <EquityInsightItem lable="CEO" value={about.ceo} />}
                    {about.sector && <EquityInsightItem lable="Sector" value={about.sector} />}
                    {about.foundedYear && <EquityInsightItem lable="Market Cap" value={about.foundedYear} />}
                    {about.webAddress && (
                        <EquityInsightItem
                            lable="Website"
                            value={
                                <a href={`https://${about.webAddress}`} target="_blank" className="break-all">
                                    {about.webAddress}
                                </a>
                            }
                        />
                    )}
                </ul>
            </div>
        </aside>
    )
}
