import { CampaignT } from '@/common/types'
import { useEffect, useState } from 'react'
import { Campaign, CampaignWithSubscription } from './Campaign'
import { FullScreenLoader } from '../common/ui/FullScreenLoader'
import useTradeModalStore from '@/trading/tradeModalStore'
import useCampaignStore from '@/trading/campaignStore'

type Props = {
    campaignsList?: { campaigns: CampaignT[] }
    subscriptions?: { subscriptions: Record<string, boolean> }
}

export const CampaignsList = ({ campaignsList, subscriptions }: Props) => {
    const [campaigns, setCampaigns] = useState<CampaignWithSubscription[] | null>(null)
    const { setSecurityCodes } = useTradeModalStore()
    const { setCurrentCampaign } = useCampaignStore()

    useEffect(() => {
        if (!campaignsList) return
        const campaignsWithActions = campaignsList.campaigns.map((campaign) => {
            const isSubscribed = subscriptions?.subscriptions?.[campaign.campaignId] ?? false
            return { ...campaign, isSubscribed, pledged: false }
        })
        setCampaigns(campaignsWithActions)
    }, [campaignsList, subscriptions])

    if (!campaigns) {
        return <FullScreenLoader className="h-20" />
    }

    return (
        <div>
            <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
                {campaigns.map((campaign) => (
                    <Campaign
                        key={campaign.campaignId}
                        campaign={campaign}
                        handleOrder={() => {
                            setSecurityCodes(campaign.targetCompaniesAsxCodes)
                            setCurrentCampaign(campaign)
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
