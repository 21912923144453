import { FC } from 'react'
import placeholderImg from '@/common/assets/intrument-placeholder.png'
import { cn } from '@/common/utils/utils'

type Props = {
    instrument: {
        companyName: string
        securityCode: string
    }
    className?: string
    nameClassName?: string
}
export const CompanyHead: FC<Props> = ({ instrument, nameClassName, className }) => {
    const securityCode = instrument.securityCode
    return (
        <div className={cn('flex py-2 space-x-3 text-inherit w-max', className)} title={instrument.companyName}>
            <div className="bg-gray-100 rounded-full p-1 w-8 h-8 sm:w-10 sm:h-10 border-1 border-gray-300 shrink-0 overflow-hidden">
                <img
                    src={`/images/companies/${securityCode.toLowerCase()}.webp`}
                    alt={securityCode}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = placeholderImg
                    }}
                />
            </div>
            <div className="flex flex-col">
                <span className="font-semibold">{securityCode}</span>
                <span className={cn('text-xs line-clamp-1', nameClassName)}>{instrument.companyName}</span>
            </div>
        </div>
    )
}
