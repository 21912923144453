import { toast } from 'sonner'

import { ContactDetails, Name, UpdateAddressesPayload, UsagePolicyKey } from '@/common/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
    updateContactDetails,
    updateName,
    updateAddresses,
    fetchUser,
    sendMobileOtp,
    verifyMobileOtp,
    updateMobile,
    updateSurveyData,
    getUsagePolicy,
    acceptPolicy,
} from '@/user/userApi'
import { Errors } from '@/common/strings'
import { API_ROUTES } from '@/common/constants'
import { SurveyT } from '@/user/survey/SurveyPage'

export const useUpdateContactDetails = () => {
    return useMutation({
        mutationFn: (data: ContactDetails) => updateContactDetails(data),
        onSuccess: () => {
            toast.success('Updated the contact details successfully')
        },
        onError: (err) => {
            console.log('Failed to update contact details', err)
            toast.error(Errors.server)
        },
    })
}

export const useUpdateName = () => {
    return useMutation({
        mutationFn: (data: Name) => updateName(data),
        onError: (err) => {
            console.log('Failed to update contact details', err)
            toast.error(Errors.server)
        },
    })
}

export const useUpdateMobile = () => {
    return useMutation({
        mutationFn: (data?: string) => updateMobile(data),
        onError: (err) => {
            console.log('Failed to update mobile and issue otp', err)
            toast.error(Errors.server)
        },
    })
}

export const useSendMobileOtp = () => {
    return useMutation({
        mutationFn: () => sendMobileOtp(),
        onError: (err) => {
            console.log('Failed to issue otp', err)
            toast.error(Errors.server)
        },
    })
}

export const useVerifyMobileOtp = () => {
    return useMutation({
        mutationFn: (data: string) => verifyMobileOtp(data),
        onError: (err) => {
            console.log('Verification not successful', err)
            toast.error(Errors.server)
        },
    })
}

export const useUpdateSurveyData = () => {
    return useMutation({
        mutationFn: (data: SurveyT) => updateSurveyData(data),
        onError: (err) => {
            console.log('Failed to update survey data', err)
            toast.error(Errors.server)
        },
    })
}

export const useUpdateAddresses = () => {
    return useMutation({
        mutationFn: (data: UpdateAddressesPayload) => updateAddresses(data),
        onError: (err) => {
            console.log('Failed to update addresses', err)
            toast.error(Errors.server)
        },
    })
}

export const useFetchUser = (isEnabled = true) => {
    return useQuery({
        queryKey: [API_ROUTES.user],
        queryFn: fetchUser,
        refetchOnMount: true,
        enabled: isEnabled,
    })
}

export const useAcceptPolicy = () => {
    return useMutation({
        mutationFn: (versionId: string) => acceptPolicy(versionId),
        onError: (err) => {
            console.log('Failed to update user consent', err)
            toast.error(Errors.server)
        },
    })
}

export const useFetchUsagePolicy = (policyKey: UsagePolicyKey) => {
    return useQuery({
        queryKey: [API_ROUTES.usagePolicies, policyKey],
        queryFn: () => getUsagePolicy(policyKey),
        refetchOnMount: true,
    })
}
