import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { FrankieOneKYCSmartUI } from '@/kyc/FrankieOneKYCSmartUI'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP_ROUTES } from '@/common/constants'
import { APP } from '@/common/strings'
import { useFetchKYC } from './kycQueries'

export function Kyc() {
    const navigate = useNavigate()
    const { data: kyc } = useFetchKYC({ enabled: true })

    useEffect(() => {
        if (!kyc) return
        if (!kyc.canInitiateKyc) {
            navigate(APP_ROUTES.PROFILE)
        }
    }, [kyc, navigate])

    if (!kyc) {
        return <FullScreenLoader />
    }

    return (
        <>
            <Helmet>
                <title>ID Verification | {APP.title}</title>
            </Helmet>
            <div className="bg-primary h-screen overflow-scroll flex justify-center items-center p-5">
                <main className="w-full max-w-[1000px] h-[100%] py-14 px-0 bg-white m-auto rounded rounded-tl-modal rounded-br-modal overflow-hidden">
                    <FrankieOneKYCSmartUI />
                </main>
            </div>
        </>
    )
}
