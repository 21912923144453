import { NavLink } from 'react-router-dom'
import { Megaphone, PieChart, TrendingUp, Wallet, CircleAlert } from 'lucide-react'
import clsx from 'clsx'
import { Logo } from '@/common/assets/svg'
import { useFeatureFlags } from '../featureFlags/featureFlags'
import useKycStore from '@/kyc/kycStore'
import { usePreloadWallet } from '@/wallet/usePreloadWallet'
import { usePreloadPortfolio } from '@/portfolio/usePreloadPortfolio'
import { useState } from 'react'

type NavItemProps = {
    icon: React.ReactNode
    title: string
    to: string
    onClick?: () => void
    onHover?: () => void
    alert?: boolean
}
const NavItem = ({ icon, title, to, onClick, onHover, alert = false }: NavItemProps) => {
    return (
        <li>
            <NavLink
                to={to}
                onClick={onClick}
                onMouseEnter={onHover}
                className={({ isActive }) => {
                    return clsx('flex space-x-3 items-center text-foreground', 'px-5 py-3 rounded-lg my-0.5', {
                        'hover:bg-lime-50': !isActive,
                        'bg-secondary-light': isActive,
                    })
                }}
            >
                {icon}
                <span className="pt-0.5">{title}</span>
                {alert && (
                    <span className="text-destructive">
                        <CircleAlert />
                    </span>
                )}
            </NavLink>
        </li>
    )
}

type Props = {
    onItemClick?: () => void
    onLogout?: () => void
}
export function AppNav({ onItemClick }: Props) {
    const [hoveredWallet, setHoveredWallet] = useState(false)
    const [hoveredPortfolio, setHoveredPortfolio] = useState(false)

    const { showPortfolioPage, showTradingPage } = useFeatureFlags()
    const { kycComplete } = useKycStore()
    const shouldPreloadWallet = kycComplete && hoveredWallet
    const shouldPreloadPortfolio = kycComplete && hoveredPortfolio

    usePreloadWallet(shouldPreloadWallet)
    usePreloadPortfolio(shouldPreloadPortfolio)

    return (
        <nav>
            <div className="block md:hidden pl-11 pt-6 pb-8">
                <NavLink to="/">
                    <Logo width={100} height={30} />
                </NavLink>
            </div>
            <ul className="my-4 mx-6 h-full">
                <NavItem onClick={onItemClick} to="/" icon={<Megaphone />} title="Campaigns" />
                {showPortfolioPage && (
                    <NavItem
                        onClick={onItemClick}
                        onHover={() => setHoveredPortfolio(true)}
                        to="/portfolio"
                        icon={<PieChart />}
                        title="Portfolio"
                    />
                )}
                {showTradingPage && (
                    <NavItem onClick={onItemClick} to="/trading" icon={<TrendingUp />} title="Trading" />
                )}
                <NavItem
                    onClick={onItemClick}
                    onHover={() => setHoveredWallet(true)}
                    to="/wallet"
                    icon={<Wallet />}
                    title="Wallet"
                />
                {/* <NavItem onClick={onItemClick} to="/research" icon={<Leaf />} title="Research" /> */}
            </ul>
        </nav>
    )
}
