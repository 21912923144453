import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { useFetchCampaignDetails, useFetchCampaignsList } from './campaignsQueries'
import { useEffect, useState } from 'react'
import { CampaignT } from '@/common/types'
import { useParams } from 'react-router'
import { H1, H2 } from '@/common/ui'
import { TakeActionBox } from './TakeActionBox'

export type CampaignDetails = {
    fieldData: Record<string, string>
}

function CampaignSection({ title, content }: { title: string; content: string }) {
    return (
        <section className="max-w-[600px] mt-12">
            <H2>{title}</H2>
            <div className="inserted-html" dangerouslySetInnerHTML={{ __html: content }} />
        </section>
    )
}

export function CampaignDetailsPage() {
    const [campaign, setCampaign] = useState<CampaignT | null>(null)
    const { campaignSlug } = useParams()
    const { data: campaignsList } = useFetchCampaignsList()
    const { data: campaignDetails } = useFetchCampaignDetails(campaign?.webflowId)

    useEffect(() => {
        if (!campaignsList) return
        const campaign = campaignsList.campaigns.find((c) => c.campaignId === campaignSlug)
        if (!campaign) throw new Error('Campaign not found')
        setCampaign(campaign)
    }, [campaignsList, campaignSlug])

    if (!campaign) return <FullScreenLoader />

    const { title, heroImage, description, followers, followersGoal, targetCompaniesAsxCodes } = campaign
    const opportunity = campaignDetails?.fieldData['opportunity---content']
    const problem = campaignDetails?.fieldData['problem---content']
    const references = campaignDetails?.fieldData['references']
    const takeAction = campaignDetails?.fieldData['take-action-now---content']

    return (
        <div className="max-w-[840px] flex flex-col items-center">
            <H1 className="text-forest text-center md:text-2xl lg:text-6xl text-balance">{title}</H1>
            <p className="text-2xl text-center text-balance">{description}</p>
            {heroImage && <img className="w-full rounded-md mt-12" src={heroImage.url} alt={heroImage.alt ?? title} />}
            <TakeActionBox
                className="max-w-[600px] mt-[-60px]"
                followers={followers}
                followersGoal={followersGoal}
                targetCompaniesAsxCodes={targetCompaniesAsxCodes}
            />
            {problem && <CampaignSection title="The problem" content={problem} />}
            {opportunity && <CampaignSection title="The opportunity" content={opportunity} />}
            {references && <CampaignSection title="References" content={references} />}
            {takeAction && <CampaignSection title="Take action now" content={takeAction} />}
        </div>
    )
}
