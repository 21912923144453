import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { HistoricalPricesRangePreset } from '@/common/types'
import { useFetchEquityDetails, useFetchEquityPrices, useFetchHistoricalPrice } from '@/trading/tradingQueries'
import { Button } from '@/common/ui/button'
import { EquityInsights } from './equityInsights'
import { HistoricalPriceChart } from './historicalPriceChart'
import { cn, getPriceRange } from '@/common/utils/utils'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { H1, H2 } from '@/common/ui'
import { APP } from '@/common/strings'
import Money from '@/common/models/money'
import { NoTradingAccountAlert } from '@/trading/noTradingAccountAlert'

const rangePresets: { range: HistoricalPricesRangePreset; label: string }[] = [
    { range: '1D', label: '1D' },
    { range: '1W', label: '1W' },
    { range: '1M', label: '1M' },
    { range: '6M', label: '6M' },
    { range: 'YTD', label: 'YTD' },
    { range: '1Y', label: '1Y' },
    { range: '3Y', label: '3Y' },
    { range: '5Y', label: '5Y' },
    { range: 'ALL', label: 'ALL' },
]

export const EquityDetails = () => {
    const { securityCode } = useParams()
    const [selectedRange, setSelectedRange] = useState<HistoricalPricesRangePreset>('1D')
    const [isReadMore, setIsReadMore] = useState(false)

    const { data: equity } = useFetchEquityDetails(securityCode)
    const { data: equityPrices } = useFetchEquityPrices(securityCode)
    const { data: pricePoints } = useFetchHistoricalPrice(equity?.securityCode, selectedRange)
    const { data: dayPricePonits } = useFetchHistoricalPrice(equity?.securityCode, '1D')
    const { data: yearPricePonits } = useFetchHistoricalPrice(equity?.securityCode, '1Y')

    const currentPrice = equityPrices?.lastPrice || equityPrices?.closingPrice

    const dayRange = useMemo(() => (dayPricePonits ? getPriceRange(dayPricePonits) : null), [dayPricePonits])
    const yearRange = useMemo(() => (yearPricePonits ? getPriceRange(yearPricePonits) : null), [yearPricePonits])
    if (!securityCode || !equity || !equityPrices) return <FullScreenLoader className="h-20" />

    return (
        <div className="">
            <Helmet>
                <title>
                    {securityCode} | {APP.title}
                </title>
            </Helmet>
            <NoTradingAccountAlert />
            <header className="flex space-x-3 mb-10">
                <div className="bg-gray-100 rounded-full p-2 w-16 h-16 border-1 border-gray-300">
                    <img src={`/images/companies/${securityCode.toLowerCase()}.webp`} />
                </div>
                <div className="flex flex-col">
                    <H1 className="mb-0">{securityCode}</H1>
                    <p className="text-sm text-accent-foreground">{equity?.description}</p>
                </div>
            </header>
            <main className="flex gap-4 flex-col xl:flex-row">
                <div className="xl:w-3/4">
                    <div className="flex flex-col md:flex-row md:items-end">
                        <div className="flex flex-col shrink-0">
                            <H2 className="mb-0">{`${Money.of(currentPrice)}`}</H2>
                            <p className="flex gap-2">
                                <span
                                    className={cn('flex gap-2', {
                                        'text-success': equityPrices?.pointsChange >= 0,
                                        'text-destructive': equityPrices?.pointsChange < 0,
                                    })}
                                >
                                    <span
                                        className={cn('size-0 border-transparent border-[7px]', {
                                            'border-b-success': equityPrices?.pointsChange >= 0,
                                            'border-t-destructive mt-2': equityPrices?.pointsChange < 0,
                                        })}
                                    />
                                    {`{${Money.of(equityPrices?.pointsChange)}`} ({equityPrices?.percentageChange})%
                                </span>
                                Today
                            </p>
                        </div>
                        <ul className="w-full flex flex-wrap justify-end space-x-2 pr-4 mt-2">
                            {rangePresets.map((preset) => (
                                <li key={preset.range}>
                                    <Button
                                        onClick={() => setSelectedRange(preset.range)}
                                        className={cn(
                                            'items-center h-auto w-10 leading-[10px] mb-1 font-normal rounded-3xl',
                                            {
                                                'bg-secondary-light': selectedRange === preset.range,
                                            },
                                        )}
                                        size="sm"
                                        variant="tertiary"
                                    >
                                        {preset.label}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <HistoricalPriceChart rangePreset={selectedRange} data={pricePoints} />
                    <H2 className="mt-10">Company Snapshot</H2>
                    <div
                        className={cn({
                            'line-clamp-[2]': !isReadMore,
                        })}
                    >
                        <p className="mb-2">{equity.about.description}</p>
                        <p>{equity.about.history}</p>
                    </div>
                    <Button
                        onClick={() => setIsReadMore((isMore) => !isMore)}
                        className="px-0"
                        size="sm"
                        variant="link"
                    >
                        {isReadMore ? 'Read Less' : 'Read More'}
                    </Button>
                </div>
                <EquityInsights
                    dayRange={dayRange}
                    yearRange={yearRange}
                    statistics={equity.statistics}
                    about={equity.about}
                />
            </main>
        </div>
    )
}
