import { ReactNode, useEffect } from 'react'
import useKycStore from './kycStore'
import { useFetchKYC } from './kycQueries'

const KycProvider = ({ children }: { children: ReactNode }) => {
    const { setKyc, setKycComplete } = useKycStore()

    const { data: kyc } = useFetchKYC({ enabled: true })

    useEffect(() => {
        if (!kyc) return
        if (kyc) {
            setKyc(kyc)
            setKycComplete(Boolean(kyc.status === 'VERIFICATION_SUCCESS'))
        }
    }, [kyc, setKyc, setKycComplete])

    return <>{children}</>
}

export default KycProvider
