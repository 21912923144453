import * as yup from 'yup'
import { Helmet } from 'react-helmet'
import { APP } from '@/common/strings'
import { useNavigate } from 'react-router'
import { APP_ROUTES } from '@/common/constants'
import { Onboarding } from '@/onboarding/OnboardingPageTemplate'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useUpdateMobile } from '@/user/userQueries'
import { useEffect } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/common/ui/select'
import { InputError } from '@/common/ui/InputError'
import { LabelInput } from '@/common/ui/LabelInput'
import { analytics } from '@/common/analytics/analytics'
import useUserStore from '@/user/userStore'

type MobileNumberForm = {
    countryCode: string
    mobile: string
}

const schema = yup
    .object({
        countryCode: yup.string().required(),
        mobile: yup.string().required('Please enter your mobile number').min(9, 'Please enter a valid mobile number'),
    })
    .required()

function removeLeadingZero(str: string): string {
    return str.startsWith('0') ? str.slice(1) : str
}

export function MobileNumber() {
    const navigate = useNavigate()
    const sendMobileOtp = useUpdateMobile()
    const { user, setUser } = useUserStore()

    const {
        handleSubmit,
        control,
        setValue,
        trigger,
        register,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })

    useEffect(() => {
        setValue('countryCode', '+61')
    }, [setValue])

    const onSubmit = (data: MobileNumberForm) => {
        const mobile = `${data.countryCode}${removeLeadingZero(data.mobile)}`
        if (!user) return
        setUser({ ...user, mobile })
        sendMobileOtp.mutate(mobile)
    }

    useEffect(() => {
        if (!sendMobileOtp.isSuccess) return
        analytics.track('Mobile Updated')
        navigate(APP_ROUTES.MOBILE_VERIFICATION)
    }, [sendMobileOtp.isSuccess, navigate])

    const handleBack = () => {
        navigate(APP_ROUTES.ADDRESS)
    }

    return (
        <>
            <Helmet>
                <title>Mobile Number | {APP.title}</title>
            </Helmet>
            <Onboarding
                title="Your Mobile Number"
                cta="Next: Verify Mobile"
                isLoading={sendMobileOtp.isPending}
                onBack={handleBack}
                onSubmit={handleSubmit(onSubmit)}
            >
                <p className="text-center text-balance mb-12">
                    Your mobile number will be used to authenticate your login and&nbsp;transactions.
                </p>
                <div className="flex flex-col w-full">
                    <div className="flex">
                        <Controller
                            name="countryCode"
                            control={control}
                            render={({ field }) => {
                                const onChange = (value: string) => {
                                    field.onChange()
                                    setValue('countryCode', value)
                                    trigger('countryCode')
                                }
                                return (
                                    <Select defaultValue="+61" onValueChange={onChange}>
                                        <SelectTrigger
                                            id={'countryCode'}
                                            className="w-[120px] rounded-l-full border-gray h-10 focus:ring-0"
                                        >
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent {...field}>
                                            <SelectGroup>
                                                <SelectItem value="+61">🇦🇺 +61</SelectItem>
                                                <SelectItem value="+64">🇳🇿 +64</SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                )
                            }}
                        />
                        <LabelInput
                            {...register('mobile')}
                            className="w-full rounded-l-[0px] rounded-r-full border border-l-0 border-gray py-2 px-4 h-10"
                            type="text"
                            id="mobile"
                            required
                            formNoValidate
                            placeholder="400 000 000"
                        />
                    </div>
                    <InputError message={errors.mobile?.message} />
                    <InputError message={errors.countryCode?.message} />
                </div>
            </Onboarding>
        </>
    )
}
