import { ReactNode, useEffect, useState } from 'react'
import { Hub } from 'aws-amplify/utils'
import { analytics } from '@/common/analytics/analytics'
import { USER_ID_TOKEN } from '@/common/constants'
import { fetchAuthSession } from 'aws-amplify/auth'
import { AuthScreen } from '@/auth/AuthPage'
import useUserStore from '@/user/userStore'
import useSixTermsStore from '@/user/policies/SixTermsStore'
import { useAcceptPolicy } from '@/user/userQueries'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const { setUser } = useUserStore()
    const { sixTerms, userAcceptedSixTerms } = useSixTermsStore()
    const acceptPolicy = useAcceptPolicy()

    // To handle user accepting terms before logging in
    useEffect(() => {
        if (!isLoggedIn || !userAcceptedSixTerms) return
        if (!acceptPolicy.isIdle) return
        sixTerms?.versionId && acceptPolicy.mutate(sixTerms?.versionId)
    }, [userAcceptedSixTerms, isLoggedIn, acceptPolicy, sixTerms?.versionId])

    const storeTokens = async () => {
        const tokens = (await fetchAuthSession()).tokens
        const idToken = tokens?.idToken?.toString()
        if (!idToken) return
        sessionStorage.setItem(USER_ID_TOKEN, idToken)
        setIsLoggedIn(true)
    }

    const clearTokens = async () => {
        sessionStorage.removeItem(USER_ID_TOKEN)
    }

    useEffect(() => {
        const checkIfAlreadyLoggedIn = async () => {
            await storeTokens()
            setIsLoading(false)
        }

        checkIfAlreadyLoggedIn()

        const hubListener = Hub.listen('auth', async ({ payload }) => {
            switch (payload.event) {
                case 'signedIn':
                    analytics.identify(payload.data.userId)
                    analytics.track('User SignedIn')
                    await storeTokens()
                    setIsLoggedIn(true)
                    break
                case 'signInWithRedirect':
                    break
                case 'signedOut':
                    analytics.track('User SignedOut')
                    await clearTokens()
                    setUser(null)
                    setIsLoggedIn(false)
                    break
            }
        })

        return () => {
            checkIfAlreadyLoggedIn()
            hubListener()
        }
    }, [setUser])

    if (isLoading) {
        return <FullScreenLoader dark />
    }

    if (isLoggedIn) {
        return <>{children}</>
    }

    return <AuthScreen />
}

export default AuthProvider
